import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Spinner, TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import styles from "./RevokeConsentModal.module.scss";
import useConsent from "../../../application/useConsent";
import Consent from "../../../domain/models/Consent";

type Props = {
  consentToUpdate: Consent | undefined;
  open: boolean;
  closeFn: () => void;
};

export const RevokeConsentModal = ({
  consentToUpdate,
  open,
  closeFn,
}: Props) => {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      consentName: "",
    },
  });

  const { consent, revokeConsentAndShowToastMessage, refresh } = useConsent(consentToUpdate?.id);
  useEffect(() => {
    if (consentToUpdate?.id) {
      refresh(consentToUpdate?.id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentToUpdate?.id])

  const actionKey = "revoke";
  const texts = {
    revoke: {
      headerText: "Revoke this consent?",
      warningText:
        "The only way to connect to consent again is to re-onboard the customer.",
      inputPlaceholder: "Consent Name",
      inputLabel: `Enter ${consentToUpdate?.name} to proceed`,
      buttonText: "Yes, Revoke",
      buttonTitle: "Revoke",
    },
  };

  const handleClose = () => {
    closeFn();
  };

  const renderSpinner = () =>
    formState.isSubmitting && <Spinner inverted absolute />;

  const onSubmit = async () => {
    if (!consent) return false;
    await revokeConsentAndShowToastMessage(consent.etag);
    closeFn();
    return false;
  };

  const validateConsentName = (value: string | undefined) =>
    !!value && value === consentToUpdate?.name;

  const renderForm = () => (
    <Box component="form" noValidate>
      <Typography className={styles.consentName} gutterBottom>
        {consentToUpdate?.name}
      </Typography>
      <Typography variant="caption" sx={{ mb: "1rem", display: "block" }}>
        {texts[actionKey].warningText}
      </Typography>
      <Typography variant="body2" sx={{ mb: "1rem", display: "block" }}>
        {texts[actionKey].inputLabel}
      </Typography>
      <TextField
        {...register("consentName", {
          required: true,
          validate: validateConsentName,
        })}
        color={formState.isValid ? "success" : "info"}
        name="consentName"
        variant="outlined"
        placeholder={texts[actionKey].inputPlaceholder}
        fullWidth
        sx={{ mb: "1rem" }}
      />
      <Typography variant="caption" sx={{ mb: "1rem", display: "block" }}>
        This consent and all associated data will be removed in 180 days.
      </Typography>
      <Button
        color="error"
        variant="contained"
        className={styles.revokeConfirmBtn}
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={!formState.isValid || formState.isSubmitting}
      >
        {texts[actionKey].buttonText}
      </Button>
    </Box>
  );

  return (
    <Dialog
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {texts[actionKey].headerText}
        <IconButton onClick={closeFn} sx={{ float: "right" }}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {renderSpinner()}
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default RevokeConsentModal;
