import httpClient from '../httpClient';
import User from '../../domain/models/User';
import authApi from '../../constants/api/authApi';

const login = (redirectUrl: string | null = null) => {
  window.location.href = authApi.login(redirectUrl);
};

const logout = (redirectUrl: string | null = null) => {
  window.location.href = authApi.logout(redirectUrl);
};

const getUserData = async (): Promise<User> => {
  const response = await httpClient.instance.get<User>(authApi.userData);
  return response.data;
};

export default {
  login,
  logout,
  getUserData,
};
