import { useQuery, useQueryClient } from "@tanstack/react-query";
import _apiKeyService from "./services/apiKeyService";
import useAuth from "../application/auth/useAuth";

export const apiKeyListQueryKey = "apiKeyList";
const useApiKeyListRepository = (apiKeyService = _apiKeyService) => {
  const { user } = useAuth();
  if (!user) throw new Error("User is not set");

  const {
    data: apiKeyList,
    error: fetchApiKeyListError,
    isFetching,
  } = useQuery({
    queryKey: [apiKeyListQueryKey],
    queryFn: () => apiKeyService.getClientApiKeys(user.clientId),
    enabled: !!user.clientId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  const queryClient = useQueryClient();
  const refreshApiKeyList = () => {
    queryClient.invalidateQueries([apiKeyListQueryKey], { exact: true });
  };

  return {
    apiKeyList,
    apiKeyListIsFetching: isFetching,
    fetchApiKeyListError,
    refreshApiKeyList,
  };
};

export default useApiKeyListRepository;
