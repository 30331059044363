import { useMutation, useQuery } from "@tanstack/react-query";
import useAuth from "../application/auth/useAuth";
import _apiKeyService from "./services/apiKeyService";
import CreateClientApiKeyDto from "./dtos/CreateClientApiKeyDto";
import useApiKeyListRepository from "./apiKeyList.repository";

interface CreateClientApiKeyMutationDto {
  clientId: string;
  createClientApiKeyDto: CreateClientApiKeyDto;
}
export const createClientApiKeyMutationKey = "createClientApiKey";

const deleteClientApiKeyMutationKey = "deleteClientApiKey";

const getApiKey = "getApiKey";

const useApiKeyRepository = (
  apiKeyId: string | undefined,
  apiKeyService = _apiKeyService,
) => {
  const { user } = useAuth();
  if (!user) throw new Error("User is not set");
  const apiKeyListRepository = useApiKeyListRepository();

  const { data: apiKey } = useQuery({
    queryKey: [getApiKey, apiKeyId],
    queryFn: async () =>
      apiKeyService.getClientApiKey(user?.clientId, apiKeyId),
    enabled: !!apiKeyId && !!user?.clientId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  const {
    mutateAsync,
    isLoading: isCreatingClientApiKey,
    data: createClientApiKeyResult,
    reset: resetCreateClientApiKey,
    isSuccess: isCreateClientApiKeySuccess,
  } = useMutation({
    mutationKey: [createClientApiKeyMutationKey],
    mutationFn: async (parameters: CreateClientApiKeyMutationDto) =>
      apiKeyService.createClientApiKey(
        parameters.clientId,
        parameters.createClientApiKeyDto,
      ),
    onSuccess: () => {
      apiKeyListRepository.refreshApiKeyList();
    },
  });

  const createClientApiKey = async (
    createClientApiKeyDto: CreateClientApiKeyDto,
    clientId: string,
  ) => mutateAsync({ clientId, createClientApiKeyDto });

  const {
    mutateAsync: deleteAsync,
    isLoading: isDeletingClientApiKey,
    isSuccess: isDeleteClientApiKeySuccess,
  } = useMutation({
    mutationKey: [deleteClientApiKeyMutationKey],
    mutationFn: async (id: string | undefined) =>
      apiKeyService.deleteClientApiKey(user.clientId, id),
    onSuccess: () => {
      apiKeyListRepository.refreshApiKeyList();
    },
  });
  const deleteClientApiKey = async () => deleteAsync(apiKeyId);

  return {
    apiKey,
    createClientApiKey,
    isCreateClientApiKeySuccess,
    isCreatingClientApiKey,
    createClientApiKeyResult,
    resetCreateClientApiKey,
    deleteClientApiKey,
    isDeletingClientApiKey,
    isDeleteClientApiKeySuccess,
  };
};

export default useApiKeyRepository;
