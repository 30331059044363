const logoSizeLimit = 20000000;
const logoSizeErrorMessage = 'Logo size should be less than 20MB';

const acceptedFileFormats = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/svg+xml',
];

const logoFormatErrorMessage = 'Logo should be in png, jpg, jpeg or svg format';
export default {
  logoSizeLimit,
  logoSizeErrorMessage,
  acceptedFileFormats,
  logoFormatErrorMessage,
};
