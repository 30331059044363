import config from '../../config';
import useRedirect from './useRedirect';
import useAuth from './useAuth';

const useLogout = () => {
  const { redirectExists, redirectUrl } = useRedirect();
  const { logout } = useAuth();

  const logoutWithRedirect = () => {
    const returnTo = redirectExists ? `${config.AUTH.LOGOUT_URL}?redirectUrl=${redirectUrl}` : config.AUTH.LOGOUT_URL;
    logout(returnTo);
  };

  return { logoutWithRedirect };
};

export default useLogout;
