import UpdateOnboardingCustomizationSettingsDto from "./UpdateOnboardingCustomizationSettingsDto";
import UpdateSettingsDto from "./UpdateSettingsDto";

export default class UpdateClientDto {
  name: string;

  onboardingCustomizationSettings: UpdateOnboardingCustomizationSettingsDto | null;

  settings: UpdateSettingsDto | null;

  systems: string[] | null;

  ifMatch: string;

  constructor(
    name: string,
    onboardingCustomizationSettings: UpdateOnboardingCustomizationSettingsDto | null,
    settings: UpdateSettingsDto | null,
    systems: string[] | null,
    ifMatch: string,
  ) {
    this.name = name;
    this.onboardingCustomizationSettings = onboardingCustomizationSettings;
    this.systems = systems;
    this.settings = settings;
    this.ifMatch = ifMatch;
  }
}
