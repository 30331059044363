import { AxiosProgressEvent } from "axios";
import api from "../../constants/api/clientsApi";
import httpClient from "../httpClient";
import Client from "../../domain/models/Client";
import UpdateClientDto from "../dtos/UpdateClientDto";

async function getClient(
  clientId,
  signal = new AbortController().signal,
  errorHandler = () => undefined,
): Promise<Client> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<Client>(
    api.client + clientId,
    requestOptions,
  );
  return response.data;
}

async function updateClient(
  clientId,
  client: UpdateClientDto,
  signal = new AbortController().signal,
  errorHandler = () => undefined,
): Promise<void> {
  const requestOptions = {
    errorHandler,
    signal,
    headers: { "If-Match": client.ifMatch },
  };
  await httpClient.instance.put(api.client + clientId, client, requestOptions);
}

async function uploadLogo(
  clientId,
  logo: FormData,
  progressCallBack:
    | ((progressEvent: AxiosProgressEvent) => void)
    | undefined = undefined,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<string | undefined> {
  const requestOptions = {
    errorHandler,
    signal,
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      progressCallBack && progressCallBack(progressEvent),
    hideErrorMessage: true,
    transformRequest: [
      (data) => data,
    ],
  };

  const response = await httpClient.instance.post(
    api.clientLogo(clientId),
    logo,
    requestOptions,
  );
  return response.headers.location;
}

export default {
  getClient,
  updateClient,
  uploadLogo,
};
