import { Route, Routes, Navigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../../application/auth/useAuth";
import httpClient from "../../../infrastructure/httpClient";
import { navigationLinks, routes } from "../../Routes";
import GetStartedPage from "../GetStartedPage/GetStartedPage";
import useClient from "../../../application/useClient";
import IsProdModeContext from "../../IsProdModeContext";
import useLogout from "../../../application/auth/useLogout";

const Root = () => {
  const { userIsFetching } = useAuth();
  const { client, clientIsFetching, fetchClientError } = useClient();
  const { logoutWithRedirect } = useLogout();
  const { enqueueSnackbar } = useSnackbar();
  const [isProdMode, setIsProdMode] = useState(false);
  const isProdModeContextValue = useMemo(() => ({ isProdMode, setIsProdMode }), [isProdMode, setIsProdMode]);

  useEffect(() => {
    if (fetchClientError) logoutWithRedirect();
  }, [fetchClientError, logoutWithRedirect]);

  useEffect(() => {
    if (!clientIsFetching) setIsProdMode(!client?.isTrial);
  }, [client?.isTrial, clientIsFetching]);

  httpClient.setup(enqueueSnackbar);

  if (userIsFetching || clientIsFetching) {
    return <Spinner />;
  }

  if (!client?.settings?.name || !client?.settings?.email) {
    return (
      <Routes>
        <Route key="getstarted" path="/getstarted" element={<GetStartedPage />} />
        <Route path="*" element={<Navigate to="/getstarted" />} />
      </Routes>
    );
  }

  const addRoutes = () => routes.map((x) => <Route key={x.id} path={x.path} element={x.element} />);

  return (
    <IsProdModeContext.Provider value={isProdModeContextValue}>
      <Routes>
        {addRoutes()}
        <Route path="*" element={<Navigate to={navigationLinks[0].route} />} />
      </Routes>
    </IsProdModeContext.Provider>
  );
};

export default Root;
