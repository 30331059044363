import { useSnackbar } from "notistack";
import _useClient from "../../../application/useClient";
import CommonPageLayout from "../../components/CommonPageLayout";
import UpdateSettingsDto from "../../../infrastructure/dtos/UpdateSettingsDto";
import UpdateClientDto from "../../../infrastructure/dtos/UpdateClientDto";
import CompanySettings, {
  CompanySettingsProps,
} from "../../components/CompanySettingsForm/CompanySettings";

const Settings = ({ useClient = _useClient }) => {
  const { client, updateClient } = useClient();

  const { enqueueSnackbar } = useSnackbar();
  const pageLayoutParams = {
    headerText: "Company Settings",
    subheaderText: "Modify your company settings here",
  };

  const onSubmit = async (data: CompanySettingsProps["defaultValues"]) => {
    if (!client) {
      throw new Error("Client is not set");
    }

    await updateClient({
      ...client,
      ifMatch: client.etag,
      settings: new UpdateSettingsDto(
        data.companyName,
        data.companyEmail,
        data.companyWebsite,
        data.companyId,
      ),
    } as UpdateClientDto);

    enqueueSnackbar("Settings updated successfully", {
      variant: "success",
    });
  };
  return (
    <CommonPageLayout {...pageLayoutParams}>
      <CompanySettings
        onSubmit={onSubmit}
        defaultValues={{
          companyName: client?.settings?.name ?? "",
          companyEmail: client?.settings?.email ?? "",
          companyWebsite: client?.settings?.website ?? "",
          companyId: client?.settings?.identifier ?? "",
        }}
      />
    </CommonPageLayout>
  );
};

export default Settings;
