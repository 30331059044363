import { KeyboardEvent, SyntheticEvent, useState, useRef } from "react";
import { ClickAwayListener, IconButton, Popper, MenuList, MenuItem, Paper, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from "./MenuButton.module.scss";

type Props = {
  tooltip?: string;
  menuItems: {
    key: string;
    label: string;
    onClick: () => void;
    tooltip?: string;
  }[];
}
export const MenuButton = ({ menuItems, tooltip }:Props) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const toggleMenu = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const closeMenu = (event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const menuItemClick = (event: Event | SyntheticEvent, onClick: () => void) => {
    closeMenu(event);
    onClick();
  };

  const menuKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const renderMenuItem = (item) => {
    if (item.tooltip) {
      return (
        <Tooltip title={item.tooltip} arrow placement="left-start">
          <MenuItem key={item.key} onClick={(event) => menuItemClick(event, item.onClick)}>
            {item.label}
          </MenuItem>
        </Tooltip>
      );
    }

    return (
      <MenuItem key={item.key} onClick={(event) => menuItemClick(event, item.onClick)}>
        {item.label}
      </MenuItem>
    );
  }

  const renderToggleMenuButton = () => {
    if (tooltip) {
      return (
        <Tooltip title={tooltip} arrow placement="left-start">
          <IconButton ref={anchorRef} onClick={toggleMenu} size="small">
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <IconButton ref={anchorRef} onClick={toggleMenu} size="small">
        <MoreVertIcon />
      </IconButton>
    );
  }

  return (
    <>
      {renderToggleMenuButton()}
      <Popper
        className={styles.menuContainer}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        disablePortal
      >
        {() => (
          <Paper>
            <ClickAwayListener onClickAway={closeMenu}>
              <MenuList autoFocusItem={open} onKeyDown={menuKeyDown}>
                {menuItems.map(renderMenuItem)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </>
  );
};

export default MenuButton;