import { useEffect, useRef, useState, KeyboardEvent } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import { Button, ButtonGroup, Typography, ClickAwayListener } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./InlineEdit.module.scss";

type Props = {
  initialValue: string|null;
  onSubmit: (newValue: string) => Promise<void>;
  readOnly?: boolean;
  disableSubmit?: boolean;
  onOpen?: () => void;
  onCancel?: () => void;
};
export const InlineEdit = ({
  initialValue,
  readOnly = false,
  disableSubmit = false,
  onSubmit,
  onOpen,
  onCancel }:Props) => {
  const { register, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      inputValue: initialValue,
    },
  });

  const formRef = useRef<HTMLFormElement>(null);
  const [text, setText] = useState(initialValue);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    setText(initialValue);
  }, [initialValue]);

  const startEditing = () => {
    if (onOpen) onOpen();
    setIsSubmitting(false);
    setText(initialValue);
    reset({ inputValue: initialValue });
    setIsEditing(true);
  };

  const cancelEditing = () => {
    if (isSubmitting) return;
    if (onCancel) onCancel();
    setIsEditing(false);
    reset({ inputValue: initialValue });
  };

  const submitNewValue = async (data) => {
    setIsSubmitting(true);
    setText(data.inputValue);
    try {
      await onSubmit(data.inputValue);
      setIsEditing(false);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
    }
  };

  const renderText = () => {
    if (readOnly) {
      return (<Typography className={styles.readonly}>{text}</Typography>)
    }

    if (isEditing) return null;

    return (
      <TextField
        fullWidth
        className={styles.editable}
        multiline
        inputProps={{ readOnly: true }}
        value={text}
        onClick={startEditing}
        sx={{ "& .MuiInputBase-root": {"background-color": "inherit" }}}
      />
    );
  };

  const keyDownHandler = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      cancelEditing();
    }

    if (event.key === "Enter" && formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", {
          cancelable: true,
          bubbles: true,
        }),
      );
    }
  }

  const renderInput = () => {
    if (readOnly || !isEditing) return null;

    return (
      <ClickAwayListener onClickAway={cancelEditing}>
        <form ref={formRef} onSubmit={handleSubmit(submitNewValue)}>
          <TextField
            disabled={isSubmitting}
            fullWidth
            multiline
            autoFocus
            {...register("inputValue", { required: true })}
            onKeyDown={keyDownHandler}
          />
          <div className={styles.buttonsContainer}>
            <ButtonGroup className={styles.buttonsGroup}>
              <LoadingButton
                loading={isSubmitting}
                disabled={disableSubmit || isSubmitting}
                variant="outlined"
                disableElevation
                type="submit">
                <CheckIcon />
              </LoadingButton>
              <Button
                disabled={isSubmitting}
                variant="outlined"
                disableElevation
                onClick={cancelEditing}>
                <CloseIcon />
              </Button>
            </ButtonGroup>
          </div>
        </form>
      </ClickAwayListener>
    );
  };

  return (
    <div className={styles.container}>
      {renderText()}
      {renderInput()}
    </div>
  );
};

export default InlineEdit;
