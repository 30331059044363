import httpClient from "../httpClient";
import Consent from "../../domain/models/Consent";
import PagedResult from "../../domain/models/PagedResult";
import UpdateConsentDto from "../../application/dtos/UpdateConsentDto";
import clientsApi from "../../constants/api/clientsApi";

async function getClientConsents(clientId, signal: AbortSignal | undefined = undefined, errorHandler = () => undefined) {
  const requestOptions = { errorHandler, signal };
  const response = await httpClient.instance.get<PagedResult<Consent>>(clientsApi.consents(clientId), requestOptions);
  return response.data;
}

async function postClientConsent(
  clientId: string,
  data,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const requestOptions = { errorHandler, signal };
  const response = await httpClient.instance.post(clientsApi.consents(clientId), data, requestOptions);
  return response.headers;
}

async function getClientConsent(
  clientId,
  consentId,
  hideErrorMessage = false,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const requestOptions = { hideErrorMessage, errorHandler, signal };
  const response = await httpClient.instance.get<Consent>(clientsApi.consent({ clientId, consentId }), requestOptions);
  return response.data;
}

async function patchClientConsent(
  clientId,
  consentId,
  data: UpdateConsentDto,
  hideErrorMessage = false,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const requestOptions = {
    hideErrorMessage,
    errorHandler,
    signal,
    headers: { "If-Match": data.IfMatch as string },
  };
  const response = await httpClient.instance.patch<Consent>(
    clientsApi.consent({ clientId, consentId }),
    data,
    requestOptions,
  );
  return response.data;
}

async function deleteClientConsent(
  clientId: string,
  consentId: string,
  hideErrorMessage = false,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const url = clientsApi.consent({ clientId, consentId });
  const requestOptions = { errorHandler, signal, hideErrorMessage };
  const response = await httpClient.instance.delete(url, requestOptions);
  return response.data;
}

export default {
  getClientConsent,
  postClientConsent,
  getClientConsents,
  patchClientConsent,
  deleteClientConsent,
};
