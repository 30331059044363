export default class UpdateOnboardingCustomizationSettingsDto {
  friendlyName: string | null;

  websiteUrl: string | null;

  panelBorderWidth: number | null;

  pageBackgroundColour: string | null;

  panelBackgroundColour: string | null;

  panelBorderColour: string | null;

  textColour: string | null;

  buttonColour: string | null;

  buttonTextColour: string | null;

  constructor(
    friendlyName: string | null,
    websiteUrl: string | null,
    panelBorderWidth: number | null,
    pageBackgroundColour: string | null,
    panelBackgroundColour: string | null,
    panelBorderColour: string | null,
    textColour: string | null,
    buttonColour: string | null,
    buttonTextColour: string | null,
  ) {
    this.friendlyName = friendlyName;
    this.websiteUrl = websiteUrl;
    this.panelBorderWidth = panelBorderWidth;
    this.pageBackgroundColour = pageBackgroundColour;
    this.panelBackgroundColour = panelBackgroundColour;
    this.panelBorderColour = panelBorderColour;
    this.textColour = textColour;
    this.buttonColour = buttonColour;
    this.buttonTextColour = buttonTextColour;
  }
}
