import { useNavigate } from "react-router-dom";
import CommonPageLayout from "../../components/CommonPageLayout";
import UpdateSettingsDto from "../../../infrastructure/dtos/UpdateSettingsDto";
import UpdateClientDto from "../../../infrastructure/dtos/UpdateClientDto";
import _useClient from "../../../application/useClient";
import CompanySettings, {
  CompanySettingsProps,
} from "../../components/CompanySettingsForm/CompanySettings";

const GetStartedPage = ({ useClient = _useClient }) => {
  const { client, updateClient } = useClient();
  const navigate = useNavigate();

  const pageLayoutParams = {
    showMyAccountModal: false,
    headerText: "Get started",
    subheaderText:
      "To initiate the setup process for API.1, we kindly request your company name and a valid company email. \n" +
      "Please note that this information can be easily modified later through the settings page.",
  };
  const onSubmit = async (data: CompanySettingsProps["defaultValues"]) => {
    if (!client) {
      throw new Error("Client is not set");
    }

    await updateClient({
      ...client,
      ifMatch: client.etag,
      settings: new UpdateSettingsDto(
        data.companyName,
        data.companyEmail,
        data.companyWebsite,
        data.companyId,
      ),
    } as UpdateClientDto);

    navigate("consents/list");
  };

  return (
    <CommonPageLayout {...pageLayoutParams}>
      <CompanySettings
        onSubmit={onSubmit}
        defaultValues={{
          companyName: "",
          companyEmail: "",
          companyWebsite: "",
          companyId: "",
        }}
      />
    </CommonPageLayout>
  );
};

export default GetStartedPage;
