import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _fileOneSettingsService from "./services/fileOneSettingsService";
import FileOneSettingsDto from "./dtos/FileOneSettings/FileOneSettingsDto";

const getFileOneSettingQueryKey = "getFileOneSetting";
const updateFileOneSettingQueryKey = "updateFileOneSetting";

const useFileOneSettingsRepository = (clientId: string | undefined) => {
  const {
    isLoading,
    data: fileOneSettings,
    error,
    isFetching,
  } = useQuery({
    queryKey: [getFileOneSettingQueryKey, clientId],
    queryFn: async () => _fileOneSettingsService.get(clientId),
    enabled: !!clientId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  const queryClient = useQueryClient();

  const refresh = (id: string) => {
    queryClient.invalidateQueries([getFileOneSettingQueryKey, id], { exact: true });
  };

  const { mutateAsync: updateAsync, isLoading: isUpdating } = useMutation({
    mutationKey: [updateFileOneSettingQueryKey],
    mutationFn: async (parameters: FileOneSettingsDto) => _fileOneSettingsService.update(clientId, parameters),
    onSuccess: (data, variables) => refresh(variables.clientId),
  });

  return {
    fileOneSettings,
    isLoading,
    error,
    isFetching,
    refresh,
    update: updateAsync,
    isUpdating,
  };
};

export default useFileOneSettingsRepository;
