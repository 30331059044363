import { OnboardingLayout } from "@zwapgrid/zwapgrid-ui-component-library";
import { Button, Step, StepButton, Stepper, Theme, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { createRef, RefObject, UIEvent } from "react";
import SelectSystemPage from "../../../../components/SelectSystems/SelectSystemPage";
import styles from "./Preview.module.scss";

type PreviewProps = {
  logoUrl: string;
  companyName: string;
  theme?: Theme | null;
  systems: string[];
  activeStep: number;
  listRef?: RefObject<HTMLUListElement>;
  onScroll: (e: UIEvent<HTMLUListElement>) => void;
  goToStep: (index: number) => () => void;
};

const Preview = ({
  logoUrl,
  companyName,
  theme,
  systems,
  activeStep,
  listRef = createRef<HTMLUListElement>(),
  onScroll,
  goToStep,
}: PreviewProps) => {
  const dummySubmit = (e) => {
    e.preventDefault();
  };

  const internalTheme = theme ?? createTheme();

  const onboardingViews = [
    {
      key: "ConsentPage",
      element: (
        <OnboardingLayout
          logoUrl={logoUrl}
          containerClassName={styles.onboardingLayoutContainer}
        >
          <div className={styles.previewWrapper}>
            <Typography>
              {`To continue, please allow Zwapgrid on behalf of ${companyName} 
              to share your data from System to ${companyName}.`}
            </Typography>
            <Typography>
              {`Zwapgrid is a subprocessor to ${companyName} and does not store or use your data.`}
            </Typography>
          </div>
          <Button variant="contained">Yes, I consent</Button>
        </OnboardingLayout>
      ),
    },
    {
      key: "SelectSystemPage",
      element: (
        <SelectSystemPage
          logoUrl={logoUrl}
          friendlyName={companyName}
          onSubmit={dummySubmit}
          systems={systems}
        />
      ),
    },
  ];

  return (
    <div className={styles.previewContainer} data-testid="preview">
      <ul
        className={styles.scrollSnapContainer}
        ref={listRef}
        onScroll={onScroll}
        data-testid="preview-list"
      >
        <ThemeProvider theme={internalTheme}>
          {onboardingViews.map((view) => (
            <li key={view.key}>{view.element}</li>
          ))}
        </ThemeProvider>
      </ul>
      <div className={styles.stepper}>
        <Button
          size="small"
          onClick={goToStep(activeStep - 1)}
          disabled={activeStep === 0}
          data-testid="back-button"
        >
          <FontAwesomeIcon icon={faAngleLeft} />
          Back
        </Button>

        <Stepper connector={null} activeStep={activeStep}>
          {onboardingViews.map((element, index) => (
            <Step key={element.key} completed={false}>
              <StepButton onClick={goToStep(index)} disabled={false} />
            </Step>
          ))}
        </Stepper>
        <Button
          size="small"
          onClick={goToStep(activeStep + 1)}
          disabled={activeStep === onboardingViews.length - 1}
          data-testid="next-button"
        >
          Next
          <FontAwesomeIcon icon={faAngleRight} />
        </Button>
      </div>
    </div>
  );
};

export default Preview;
