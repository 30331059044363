import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Spinner, TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import ApiKey from "../../../domain/models/ApiKey";
import useApiKey from "../../../application/useApiKey/useApiKey";
import styles from "./DeleteApiKeyModal.module.scss";

type Props = {
  apiKeyToDelete: ApiKey | undefined;
  open: boolean;
  close: () => void;
};

const DeleteApiKeyModal = ({ apiKeyToDelete, open, close }: Props) => {
  const { register, handleSubmit, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      apiKeyName: "",
    },
  });

  const { apiKey, deleteClientApiKey } = useApiKey({
    apiKeyId: apiKeyToDelete?.id,
  });

  const actionKey = "revoke";
  const texts = {
    revoke: {
      headerText: "Delete this API Key?",
      warningText:
        "This will render this key unusable. This action is irrevocable",
      inputPlaceholder: "API Key Name",
      inputLabel: `Enter ${apiKeyToDelete?.name} to proceed`,
      buttonText: "Yes, Delete API Key",
      buttonTitle: "Delete",
    },
  };

  const renderSpinner = () =>
    formState.isSubmitting && <Spinner inverted absolute />;

  const onSubmit = async () => {
    if (!apiKey) return false;
    await deleteClientApiKey();
    close();
    return false;
  };

  const validateApiKeyName = (value: string | undefined) =>
    !!value && value === apiKeyToDelete?.name;

  const renderForm = () => (
    <Box component="form" noValidate>
      <Typography className={styles.apiKeyName} gutterBottom>
        {apiKeyToDelete?.name}
      </Typography>
      <Typography variant="caption" sx={{ mb: "1rem", display: "block" }}>
        {texts[actionKey].warningText}
      </Typography>
      <Typography variant="body2" sx={{ mb: "1rem", display: "block" }}>
        {texts[actionKey].inputLabel}
      </Typography>
      <TextField
        {...register("apiKeyName", {
          required: true,
          validate: validateApiKeyName,
        })}
        color={formState.isValid ? "success" : "info"}
        name="apiKeyName"
        variant="outlined"
        placeholder={texts[actionKey].inputPlaceholder}
        fullWidth
        sx={{ mb: "1rem" }}
      />

      <Button
        color="error"
        variant="contained"
        className={styles.deleteButton}
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={!formState.isValid || formState.isSubmitting}
      >
        {texts[actionKey].buttonText}
      </Button>
    </Box>
  );

  return (
    <Dialog
      maxWidth="xs"
      PaperProps={{ sx: { borderRadius: "1.5rem" } }}
      open={open}
      onClose={close}
    >
      <DialogTitle>
        {texts[actionKey].headerText}
        <IconButton onClick={close} sx={{ float: "right" }}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {renderSpinner()}
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteApiKeyModal;
