import { ChangeEvent, createRef, RefObject } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import { MuiColorInput } from 'mui-color-input';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import logoUploadRequirements from "../../../../../constants/UploadFileSizeLimits";
import { ReactComponent as BrandingIcon } from "../../../../assets/icons/Branding.svg";
import styles from "../Accordion.module.scss";
import {FormValues} from "../../BrandingPage";

// onClick is mainly used by test to check if the button is clicked
// InputRef doesn't get mounted in test, so we can't test the inputRef.current.click()
type CustomizeUserOnboardingAccordionProps = {
  onSelectFile: (event: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: RefObject<HTMLInputElement>;
  control: Control<FormValues>;
  disableInputs?: boolean;
  fieldErrors?: FieldErrors<FormValues>;
  onClick?: () => void;
  isExtendedCustomizationShown?: boolean;
};
const CustomizeUserOnboardingAccordion = ({
  onSelectFile,
  inputRef = createRef<HTMLInputElement>(),
  control,
  disableInputs = false,
  fieldErrors = {},
  onClick,
  isExtendedCustomizationShown = false,
}: CustomizeUserOnboardingAccordionProps) => {
  const localTheme = createTheme();

  const onButtonClick = () => {
    inputRef.current?.click();
  };

  const renderExtendedCustomization = () => {
    if (!isExtendedCustomizationShown) return null;
    return (
      <ThemeProvider theme={localTheme}>
        <div>
          <label htmlFor="panelBorderWidth">
            <Typography variant="subtitle2">Panel Border Width (px)</Typography>
          </label>
          <Controller
            name="panelBorderWidth"
            control={control}
            render={({ field }) => (
              <TextField
                id="panelBorderWidth"
                {...field}
                disabled={disableInputs}
                aria-disabled={disableInputs}
                fullWidth
                type="number"
                error={!!fieldErrors.panelBorderWidth}
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="backgroundColour">
            <Typography variant="subtitle2">Background Color</Typography>
          </label>
          <Controller
            name="backgroundColour"
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                className={styles.colorInput}
                format="hex"
                isAlphaHidden
                fullWidth
                disabled={disableInputs}
                error={!!fieldErrors.backgroundColour}
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="panelBackgroundColour">
            <Typography variant="subtitle2">Panel Background Color</Typography>
          </label>
          <Controller
            name="panelBackgroundColour"
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                className={styles.colorInput}
                format="hex"
                isAlphaHidden
                fullWidth
                disabled={disableInputs}
                error={!!fieldErrors.panelBackgroundColour}
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="borderColour">
            <Typography variant="subtitle2">Border Color</Typography>
          </label>
          <Controller
            name="borderColour"
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                className={styles.colorInput}
                format="hex"
                isAlphaHidden
                fullWidth
                disabled={disableInputs}
                error={!!fieldErrors.borderColour}
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="textColour">
            <Typography variant="subtitle2">Text Color</Typography>
          </label>
          <Controller
            name="textColour"
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                className={styles.colorInput}
                format="hex"
                isAlphaHidden
                fullWidth
                disabled={disableInputs}
                error={!!fieldErrors.textColour}
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="buttonColour">
            <Typography variant="subtitle2">Button Color</Typography>
          </label>
          <Controller
            name="buttonColour"
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                className={styles.colorInput}
                format="hex"
                isAlphaHidden
                fullWidth
                disabled={disableInputs}
                error={!!fieldErrors.buttonColour}
              />
            )}
          />
        </div>

        <div>
          <label htmlFor="buttonTextColour">
            <Typography variant="subtitle2">Button Text Color</Typography>
          </label>
          <Controller
            name="buttonTextColour"
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                className={styles.colorInput}
                format="hex"
                isAlphaHidden
                fullWidth
                disabled={disableInputs}
                error={!!fieldErrors.buttonTextColour}
              />
            )}
          />
        </div>
      </ThemeProvider>
    );
  }

  return (
    <Accordion disableGutters className={styles.accordion}>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
        className={styles.accordionSummary}
      >
        <Typography
          variant="h5"
          component="h2"
          className={styles.accordionSummaryContent}
        >
          <BrandingIcon className={styles.accordionSummaryContentIcon} />
          Customize User Onboarding
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <div className={styles.inputsContainer}>
          <div>
            <label htmlFor="logo">
              <Typography variant="subtitle2">Logo</Typography>
            </label>
            <input
              type="file"
              name="logo"
              data-testid="logo"
              onChange={onSelectFile}
              hidden
              ref={inputRef}
              accept={logoUploadRequirements.acceptedFileFormats.join(", ")}
              disabled={disableInputs}
            />
            <Button
              title="Upload logo"
              color="primary"
              variant="outlined"
              size="large"
              fullWidth
              className={styles.logoButton}
              onClick={onClick ?? onButtonClick}
              disabled={disableInputs}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: "1rem" }} />
              <Typography>Logo</Typography>
            </Button>
          </div>
          {renderExtendedCustomization()}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomizeUserOnboardingAccordion;
