import { useContext, useState } from "react";
import { Typography, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import classNames from "classnames";
import dateFormat from "dateformat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEllipsis, faXmark } from "@fortawesome/free-solid-svg-icons";

import CommonPageLayout from "../../components/CommonPageLayout";
import RevokeConsentModal from "../../components/RevokeConsentModal/RevokeConsentModal";
import DeleteConsentModal from "../../components/DeleteConsentModal/DeleteConsentModal";
import ProdModeSwitch from "../../components/ProdModeSwitch/ProdModeSwitch";
import EditConsentName from "../../components/EditConsentName";
import MenuButton from "../../components/MenuButton";
import _useConsentList from "../../../application/useConsentList";
import _useClient from "../../../application/useClient";
import IsProdModeContext from "../../IsProdModeContext";
import Consent from "../../../domain/models/Consent";
import ConsentStatus from "../../../domain/models/ConsentStatus";

import styles from "./ConsentListPage.module.scss";

const consentStatusLayoutSettings = {
  [ConsentStatus.Created]: {
    text: "Created (Not accepted yet)",
    className: null,
  },
  [ConsentStatus.Accepted]: {
    text: "Accepted",
    className: styles.accepted,
  },
  [ConsentStatus.Revoked]: {
    text: "Revoked",
    className: styles.rejected,
  },
  [ConsentStatus.Inactive]: {
    text: "Inactive",
    className: styles.inactive,
  },
};

export const ConsentListPage = ({ useConsentList = _useConsentList, useClient = _useClient }) => {
  const [currentConsent, setCurrentConsent] = useState<Consent>();
  const { consentList, isLoading } = useConsentList();
  const { client, clientIsFetching } = useClient();
  const [openRevokeModal, setOpenRevokeModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { isProdMode, setIsProdMode } = useContext(IsProdModeContext);

  const filteredConsentList = consentList?.data.filter((x) => x.isTest === !isProdMode);

  const pageLayoutParams = {
    headerText: "Consents",
    subheaderText: "",
    headerElements: [
      <ProdModeSwitch
        key="2"
        isProdMode={isProdMode}
        setIsProdMode={setIsProdMode}
        isTrial={Boolean(client?.isTrial)}
        isLoading={clientIsFetching}
        disabled={!client}
      />,
    ],
  };

  const handleOpenDeleteModal = (consent: Consent) => {
    setCurrentConsent(consent);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurrentConsent(undefined);
  };

  const handleOpenRevokeModal = (consent: Consent) => {
    setCurrentConsent(consent);
    setOpenRevokeModal(true);
  };
  const handleCloseRevokeModal = () => {
    setOpenRevokeModal(false);
    setCurrentConsent(undefined);
  };

  const renderSpinner = () => isLoading && <Spinner inverted absolute />;

  const renderNoDataMessage = () => {
    if (isLoading || !filteredConsentList) return null;

    if (filteredConsentList.length > 0) return null;

    return <Typography className={styles.emptyList}>You do not have any consents yet</Typography>;
  };

  const renderStatusIcon = (status: ConsentStatus) => {
    switch (status) {
      case ConsentStatus.Accepted:
        return <FontAwesomeIcon className={styles.statusIcon} icon={faCheck} />;

      case ConsentStatus.Revoked:
        return <FontAwesomeIcon className={classNames(styles.statusIcon)} icon={faXmark} />;

      case ConsentStatus.Inactive:
        return <FontAwesomeIcon className={classNames(styles.statusIcon)} icon={faXmark} />;

      case ConsentStatus.Created:
      default:
        return <FontAwesomeIcon className={styles.statusIcon} icon={faEllipsis} />;
    }
  };

  const renderMoreOptions = (consent: Consent) => {
    if (consent.status === ConsentStatus.Revoked) {
      return (
        <Tooltip title="Delete Consent" arrow placement="left-start">
          <IconButton onClick={() => handleOpenDeleteModal(consent)} size="small">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      );
    }

    const menuItems = [
      {
        key: "revoke",
        label: "Revoke",
        tooltip: "Revoke Consent",
        onClick: () => handleOpenRevokeModal(consent),
      },
      {
        key: "delete",
        label: "Delete",
        tooltip: "Delete Consent",
        onClick: () => handleOpenDeleteModal(consent),
      },
    ];

    return <MenuButton tooltip="Show actions list" menuItems={menuItems} />;
  };

  const renderDataRow = (entity: Consent, index: number) => {
    const layoutSettings = consentStatusLayoutSettings[entity.status];

    return (
      <div key={index} className={classNames(styles.tableRow, layoutSettings.className)}>
        <Typography className={classNames(styles.tableCell, styles.iconsContainer)}>
          {renderStatusIcon(entity.status)}
        </Typography>
        <span className={classNames(styles.tableCell, styles.consentName)}>
          <EditConsentName entity={entity} />
        </span>
        <Typography className={styles.tableCell}>{dateFormat(entity.createdOn, "mmmm d, yyyy. H:MM")}</Typography>
        <Typography className={classNames(styles.tableCell, styles.consentName)}>{entity.source ?? "..."}</Typography>
        <Typography className={classNames(styles.tableCell, layoutSettings.className)}>{layoutSettings.text}</Typography>
        <Typography className={classNames(styles.tableCell)}>{renderMoreOptions(entity)}</Typography>
      </div>
    );
  };

  const revokeConsentModalParams = {
    consentToUpdate: currentConsent,
    open: openRevokeModal,
    closeFn: handleCloseRevokeModal,
  };

  const renderRevokeConsentModal = () => currentConsent && <RevokeConsentModal {...revokeConsentModalParams} />;
  const renderDeleteConsentModal = () =>
    currentConsent && <DeleteConsentModal consent={currentConsent} open={openDeleteModal} close={handleCloseDeleteModal} />;

  const renderDataTable = () => {
    if (isLoading || !filteredConsentList) return null;

    if (filteredConsentList.length < 1) return null;

    return (
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <div className={styles.tableHeaderCell} />
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Name
          </Typography>
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Date
          </Typography>
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Connected System
          </Typography>
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Status
          </Typography>
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Actions
          </Typography>
        </div>
        {filteredConsentList.map((x, index) => renderDataRow(x, index))}
        {renderRevokeConsentModal()}
        {renderDeleteConsentModal()}
      </div>
    );
  };

  return (
    <CommonPageLayout {...pageLayoutParams}>
      {renderSpinner()}
      {renderNoDataMessage()}
      {renderDataTable()}
    </CommonPageLayout>
  );
};

export default ConsentListPage;
