import ConsentStatus from "../../domain/models/ConsentStatus";

export default class UpdateConsentDto {
  public Status: ConsentStatus | null;

  public Name: string | null;

  public IfMatch: string | undefined;

  constructor(status: ConsentStatus | null, name: string | null, ifMatch: string | undefined) {
    this.Status = status;
    this.Name = name;
    this.IfMatch = ifMatch;
  }
}
