import { useQuery, useQueryClient } from "@tanstack/react-query";
import useAuth from "../application/auth/useAuth";
import _consentService from "./services/consentService";

export const consentListQueryKey = "consentList";

const useConsentListRepository = (consentService = _consentService) => {
  const { user } = useAuth();

  const {
    isLoading,
    data: consentList,
    error: fetchConsentListError,
  } = useQuery({
    queryKey: [consentListQueryKey],
    queryFn: () => consentService.getClientConsents(user?.clientId),
    enabled: !!user?.clientId,
    refetchOnWindowFocus: true,
    refetchOnMount: false,
    refetchInterval: 10000,
    retry: false,
  });

  const queryClient = useQueryClient();
  const refreshConsentList = async (): Promise<void> =>
    queryClient.invalidateQueries([consentListQueryKey], { exact: true });

  return {
    consentList,
    isLoading,
    fetchConsentListError,
    refreshConsentList,
  };
};

export default useConsentListRepository;
