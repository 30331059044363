import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Spinner, TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import Consent from "../../../domain/models/Consent";
import useConsent from "../../../application/useConsent";
import styles from "./DeleteConsentModal.module.scss";

export interface DeleteConsentModalProps {
  open: boolean;
  consent: Consent;
  close: () => void;
}

const DeleteConsentModal = ({ open, consent, close }: DeleteConsentModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    defaultValues: {
      consentName: "",
    },
  });
  const { deleteConsent } = useConsent(consent.id);

  const onSubmit = async () => {
    await deleteConsent();
    close();
  };

  const renderForm = () => (
    <Box component="form" noValidate>
      <Typography className={styles.consentName} gutterBottom>
        {consent.name}
      </Typography>
      <Typography variant="body2" sx={{ mb: "1rem", display: "block" }}>
        {`Enter ${consent.name} to proceed`}
      </Typography>
      <TextField
        {...register("consentName", {
          required: true,
          validate: (value) => value === consent.name,
        })}
        color={isValid ? "success" : "info"}
        name="consentName"
        variant="outlined"
        placeholder="Consent Name"
        fullWidth
        sx={{ mb: "1rem" }}
      />
      <Typography variant="caption" sx={{ mb: "1rem", display: "block" }}>
        This consent and all associated data will be removed immediately.
      </Typography>
      <Button
        color="error"
        variant="contained"
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={!isValid || isSubmitting}
      >
        Yes, Delete
      </Button>
    </Box>
  );

  return (
    <Dialog maxWidth="xs" PaperProps={{ sx: { borderRadius: "1.5rem" } }} open={open} onClose={close}>
      <DialogTitle>
        Delete this consent?
        <IconButton onClick={close} sx={{ float: "right" }}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isSubmitting && <Spinner inverted absolute />}
        {renderForm()}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConsentModal;
