import { useSnackbar } from "notistack";
import useAuth from "../auth/useAuth";
import useApiKeyRepository from "../../infrastructure/apiKey.repository";
import ApiKeyExpirationTypeDto from "../../infrastructure/dtos/ApiKeyExpirationTypeDto";
import CreateClientApiKeyDto from "../../infrastructure/dtos/CreateClientApiKeyDto";

type ApiKeyHookProps = {
  apiKeyId?: string;
};
export default ({ apiKeyId }: ApiKeyHookProps = {}) => {
  const apiKeyRepo = useApiKeyRepository(apiKeyId);
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const createApiKey = async (name: string, description: string, expiresIn: ApiKeyExpirationTypeDto, isTest: boolean) => {
    if (!user) throw new Error("User not found");
    return apiKeyRepo.createClientApiKey(
      new CreateClientApiKeyDto(name, description, isTest, Number(expiresIn)),
      user.clientId,
    );
  };

  const deleteApiKey = async () => {
    try {
      await apiKeyRepo.deleteClientApiKey();
      enqueueSnackbar("API Key deleted successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error deleting API Key", { variant: "error" });
    }
  };

  return {
    ...apiKeyRepo,
    createClientApiKey: createApiKey,
    deleteClientApiKey: deleteApiKey,
  };
};
