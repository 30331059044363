import { AxiosResponse } from "axios";
import httpClient from "../httpClient";
import PagedResult from "../../domain/models/PagedResult";
import ApiKey from "../../domain/models/ApiKey";
import CreateClientApiKeyDto from "../dtos/CreateClientApiKeyDto";
import CreateClientApiKeyResultDto from "../dtos/CreateClientApiKeyResultDto";
import clientsApi from "../../constants/api/clientsApi";

async function getClientApiKey(
  clientId: string,
  apiKeyId: string | undefined,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const url = clientsApi.apiKey({ clientId, apiKeyId });
  const requestOptions = { errorHandler, signal };
  const response = await httpClient.instance.get<ApiKey>(url, requestOptions);
  return response.data;
}

async function getClientApiKeys(
  clientId: string,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const url = clientsApi.apiKeys(clientId);
  const requestOptions = { errorHandler, signal };
  const response = await httpClient.instance.get<PagedResult<ApiKey>>(
    url,
    requestOptions,
  );
  return response.data;
}

async function createClientApiKey(
  clientId: string,
  createClientApiKeyDto: CreateClientApiKeyDto,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const url = clientsApi.apiKeys(clientId);
  const requestOptions = { errorHandler, signal };
  const response = await httpClient.instance.post<
    CreateClientApiKeyDto,
    AxiosResponse<CreateClientApiKeyResultDto>
  >(url, createClientApiKeyDto, requestOptions);
  return response.data;
}

async function deleteClientApiKey(
  clientId: string,
  apiKeyId: string | undefined,
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
) {
  const url = clientsApi.apiKey({ clientId, apiKeyId });
  const requestOptions = { errorHandler, signal };
  const response = await httpClient.instance.delete(url, requestOptions);
  return response.data;
}

export default {
  getClientApiKey,
  getClientApiKeys,
  createClientApiKey,
  deleteClientApiKey,
};
