import { Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";

export type ProdModeSwitchProps = {
  isProdMode: boolean;
  setIsProdMode: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  isTrial?: boolean;
  isLoading?: boolean;
};

const ProdModeSwitch = ({
  isProdMode,
  setIsProdMode,
  disabled,
  isTrial = false,
  isLoading = false,
}: ProdModeSwitchProps) => {
  const renderSwitch = () => (
    <Stack direction="row" alignItems="center" style={{ marginLeft: "1rem" }}>
      <Typography>Test</Typography>
      <Switch
        checked={isProdMode}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setIsProdMode(event.target.checked)
        }
        color="primary"
        disabled={disabled || isTrial}
      />
      <Typography>Production</Typography>
    </Stack>
  );
  if (isLoading)
    return <Skeleton style={{ marginLeft: "1rem" }}>{renderSwitch()}</Skeleton>;

  if (isTrial)
    return (
      <Tooltip
        arrow
        placement="bottom-start"
        title="To access production mode, please contact our sales team."
      >
        {renderSwitch()}
      </Tooltip>
    );
  return renderSwitch();
};
export default ProdModeSwitch;
