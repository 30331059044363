import styles from "../BrandingPage/BrandingPage.module.scss";
import CommonPageLayout from "../../components/CommonPageLayout";
import FileOneAccordion from "./FileOne/FileOneAccordion.component";

const SystemSettingsPage = () => {
  const pageLayoutParams = {
    headerElements: [],
    pageContentClassName: styles.pageContent,
  };
  return (
    <CommonPageLayout {...pageLayoutParams}>
      <FileOneAccordion />
    </CommonPageLayout>
  );
};

export default SystemSettingsPage;
