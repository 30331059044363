import config from '../../config';

function getOnboardingLink(id: string): string {
  return `${config.CUSTOMER_PORTAL_HOST}/consent/${id}`;
}

async function copyValue(value: string) {
  await navigator.clipboard.writeText(value);
}

async function copyOnboardingLink(id: string) {
  await copyValue(getOnboardingLink(id));
}

export default {
  copyValue,
  getOnboardingLink,
  copyOnboardingLink,
};
