import { forwardRef, Ref } from "react";
import styles from "./SystemButton.module.scss";
import { SupportedSystem } from "../../../constants/SupportedSystems";

type SystemButtonProps = {
  value: string;
  system: SupportedSystem;
  checked: boolean;
  onChange: (event) => void;
  name?: string;
  disabled?: boolean;
};
// TODO move this component to common-ui library and reuse it in onboarding service
// Either disable this or disable eslint-prefer-arrow-callback
// eslint-disable-next-line react/display-name
export const SystemButton = forwardRef(
  (
    {
      value,
      system,
      checked = false,
      onChange,
      name = "SystemButton",
      disabled = false,
    }: SystemButtonProps,
    ref: Ref<HTMLInputElement>,
  ) => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onChange(event);
      }
    };

    return (
      <label
        className={styles.sourceElement}
        key={value}
        htmlFor={value}
        aria-disabled={disabled}
        title={`Select ${system.name}`}
      >
        <input
          ref={ref}
          id={value}
          type="checkbox"
          title={`${system.name} checkbox`}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          onClick={onChange}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
        <img src={system.logoUrl} alt={`${system.name} Logo`} />
      </label>
    );
  },
);

export default SystemButton;
