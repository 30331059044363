import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosProgressEvent } from "axios";
import useAuth from "../application/auth/useAuth";
import clientService from "./services/clientService";
import UpdateClientDto from "./dtos/UpdateClientDto";
import UpdateOnboardingCustomizationSettingsDto from "./dtos/UpdateOnboardingCustomizationSettingsDto";

const clientQueryKey = "client";

export function useClientRepository() {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const {
    isFetching: clientIsFetching,
    data: client,
    error: fetchClientError,
  } = useQuery({
    queryKey: [clientQueryKey, user?.clientId],
    queryFn: () => clientService.getClient(user?.clientId),
    enabled: !!user?.clientId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  const mutateOnboardingSettings = useMutation({
    mutationFn: async (data: {
      logoDto: FormData | null;
      clientSettings: {
        customizationSettings: UpdateOnboardingCustomizationSettingsDto,
        systems: string[];
      } | null;
      progressCallBack: ((progressEvent: AxiosProgressEvent) => void) | undefined;
    }): Promise<void> => {
      if (!client || clientIsFetching) return;
      const { logoDto, clientSettings, progressCallBack } = data;

      if (clientSettings) {
        const updateClientDto = new UpdateClientDto(
          client.name,
          clientSettings.customizationSettings,
          null,
          clientSettings.systems,
          client.etag,
        );
        await clientService.updateClient(user?.clientId, updateClientDto);
      }

      if (logoDto) {
        await clientService.uploadLogo(user?.clientId, logoDto, progressCallBack);
      }
    },
    onSuccess: async () => queryClient.refetchQueries([clientQueryKey, user?.clientId]),
  });

  const mutateClient = useMutation({
    mutationFn: async (data: UpdateClientDto): Promise<void> => {
      await clientService.updateClient(user?.clientId, data);
    },
    onSuccess: async () => queryClient.refetchQueries([clientQueryKey, user?.clientId]),
  });

  return {
    client,
    clientIsFetching,
    fetchClientError,
    updateClient: mutateClient.mutateAsync,
    clientIsUpdating: mutateClient.isLoading,
    clientUpdatedSuccessfully: mutateClient.isSuccess,
    updateOnboardingSettings: mutateOnboardingSettings.mutateAsync,
    onboardingSettingsAreUpdating: mutateOnboardingSettings.isLoading,
    onboardingSettingsUpdatedSuccessfully: mutateOnboardingSettings.isSuccess,
  };
}

export default useClientRepository;
