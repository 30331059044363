import { useState } from "react";
import InlineEdit from "../InlineEdit";
import useConsent from "../../../application/useConsent";
import Consent from "../../../domain/models/Consent";
import ConsentStatus from "../../../domain/models/ConsentStatus";

type Props = {
  entity: Consent;
};
export const EditConsentName = ({ entity }:Props) => {
  const [consentId, setConsentId] = useState<string|undefined>(undefined);
  const { consent, updateConsentName, consentIsBeingFetched } = useConsent(consentId);

  const onEdit = () => {
    setConsentId(entity.id);
  }

  const onEditCancel = () => {
    setConsentId(undefined);
  }

  const onSubmit = async (newValue: string) => {
    if (!consent) return;
    await updateConsentName(newValue, consent.etag);
    setConsentId(undefined);
  };

  return (
    <InlineEdit
      initialValue={entity.name}
      readOnly={entity.status === ConsentStatus.Revoked || entity.status === ConsentStatus.Inactive}
      disableSubmit={consentIsBeingFetched}
      onSubmit={onSubmit}
      onOpen={onEdit}
      onCancel={onEditCancel}
    />
  );
};

export default EditConsentName;
