import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useForm, useWatch } from "react-hook-form";
import classNames from "classnames";
import {Badge, Button, Theme, Typography} from "@mui/material";
import { StyledEngineProvider, createTheme } from "@mui/material/styles";
import chroma from "chroma-js";
import CommonPageLayout from "../../components/CommonPageLayout";
import { ReactComponent as SaveIcon } from "../../assets/icons/Save.svg";
import styles from "./BrandingPage.module.scss";
import _useClient from "../../../application/useClient";
import _useFeatureList from "../../../application/useFeatureList";
import logoUploadRequirements from "../../../constants/UploadFileSizeLimits";
import Features from "../../../constants/Features";
import Defaults from "../../../constants/OnboardingCustomizationDefaults";
import UploadingFileToast from "../../components/UploadingFileToast/UploadingFileToast.component";
import Preview from "./components/Preview";
import YourCompanyAccordion from "./components/YourCompanyAccordion";
import ERPSystemsAccordion from "./components/ERPSystemsAccordion";
import CustomizeUserOnboardingAccordion from "./components/CustomizeUserOnboardingAccordion";
import UpdateOnboardingCustomizationSettingsDto
  from "../../../infrastructure/dtos/UpdateOnboardingCustomizationSettingsDto";

export type BadgeType = {
  color:
    | "primary"
    | "error"
    | "info"
    | "default"
    | "warning"
    | "secondary"
    | "success";
  content: number | string;
  inputContent: number | string;
};

export type FormValues = {
  companyName: string;
  companyWebsite: string;
  panelBorderWidth: number;
  backgroundColour: string;
  panelBackgroundColour: string;
  borderColour: string;
  textColour: string;
  buttonColour: string;
  buttonTextColour: string;
  systems: string[];
};

const BrandingPage = ({
  useClient = _useClient,
  useFeature: useFeatureList = _useFeatureList,
  showSnackbar: showUpdatingStatusPanel = false,
}) => {
  const {
    client,
    clientIsFetching,
    updateOnboardingSettings,
    onboardingSettingsAreUpdating: isUpdating,
    uploadingLogoProgress,
    onboardingSettingsUpdatedSuccessfully: updateSuccessfull,
  } = useClient();
  const { features } = useFeatureList();
  const [isExtendedCustomizationShown, setIsExtendedCustomizationShown] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [updatingStatusPanelOpened, setUpdatingStatusPanelOpened] = useState(
    showUpdatingStatusPanel,
  );
  const [isYourCompanyOpen, setIsYourCompanyOpen] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [previewTheme, setPreviewTheme] = useState(createTheme());

  const {
    control,
    handleSubmit,
    reset,
    resetField,
    trigger,
    formState: { isDirty: formIsDirty, errors: formErrors, dirtyFields },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      companyWebsite: "",
      panelBorderWidth: 0,
      backgroundColour: "",
      panelBackgroundColour: "",
      borderColour: "",
      textColour: "",
      buttonColour: "",
      buttonTextColour: "",
      systems: [""],
    },
  });

  const formIsValid = Object.keys(formErrors).length === 0;
  const companyNameWatch = useWatch({ name: "companyName", control });
  const systemsWatch = useWatch({ name: "systems", control });

  const globalWatch = useWatch({ control });
  useEffect(() => {
    const newTheme = {
      ...previewTheme,
      palette: {
        ...previewTheme.palette,
        primary: {
          ...previewTheme.palette.primary,
          main: globalWatch.buttonColour || Defaults.buttonColour,
          contrastText: globalWatch.buttonTextColour || Defaults.buttonTextColour,
          dark: chroma.hex(globalWatch.buttonColour || Defaults.buttonColour).darken(1).hex(),
        },
      },
      typography: {
        ...previewTheme.typography,
        body1: {
          ...previewTheme.typography.body1,
          color: globalWatch.textColour || Defaults.textColour,
        },
        h6: {
          ...previewTheme.typography.h6,
          color: globalWatch.textColour || Defaults.textColour,
        },
      },
    }
    setPreviewTheme(newTheme as Theme);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalWatch, setPreviewTheme]);

  // Shared state for Preview
  const totalSteps = 2;
  const [activeStep, setActiveStep] = useState(0);
  const previewContainer = useRef<HTMLUListElement>(null);
  const [scrollElementWidth, setScrollElementWidth] = useState(0);

  useLayoutEffect(() => {
    if (previewContainer.current) {
      setScrollElementWidth(previewContainer.current.offsetWidth);
    }
  }, [previewContainer]);

  const getValidStep = (step: number) => {
    switch (true) {
      case step < 0:
        return 0;
      case step > totalSteps - 1:
        return totalSteps - 1;
      default:
        return step;
    }
  };
  const handleScroll = () => {
    if (previewContainer.current) {
      const step = getValidStep(
        Math.round(previewContainer.current.scrollLeft / scrollElementWidth),
      );

      if (step !== activeStep) setActiveStep(step);
    }
  };
  const scrollToStep = (step: number) => {
    if (previewContainer.current) {
      previewContainer.current.scrollTo?.({
        left: step * scrollElementWidth,
        behavior: "smooth",
      });
    }
  };
  const handleGoToStep = (step: number) => () => {
    const validStep = getValidStep(step);
    setActiveStep(validStep);
    scrollToStep(validStep);
  };

  const handleYourCompanyAccordionOpen = () => {
    handleGoToStep(0)();
    setIsYourCompanyOpen(true);
  };

  const handleYourCompanyAccordionClose = () => {
    setIsYourCompanyOpen(false);
  };

  const onSaveIsDisabled = () =>
    !(formIsDirty || selectedFile) ||
    isUpdating ||
    systemsWatch.length === 0 ||
    !formIsValid;

  const saveBadgeContent = !onSaveIsDisabled() ? "" : 0;

  const yourCompanyBadge: BadgeType = {
    color: dirtyFields.companyName && !companyNameWatch ? "error" : "info",
    content: !formIsValid && !clientIsFetching && !isYourCompanyOpen ? "" : 0,
    inputContent: !companyNameWatch && !clientIsFetching ? "" : 0,
  };

  const erpSystemsBadge: BadgeType = {
    color: dirtyFields.systems ? "error" : "info",
    content: systemsWatch.length === 0 ? "" : 0,
    inputContent: 0,
  };

  const onSave = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", {
          cancelable: true,
          bubbles: true,
        }),
      );
    }
  };

  const onLogoUploadingStatusPanelClose = () => {
    setUpdatingStatusPanelOpened(false);
  };

  const pageLayoutParams = {
    headerElements: [
      <Badge key="1" badgeContent={saveBadgeContent} color="warning">
        <Button
          type="submit"
          variant="elevatedContained"
          className={classNames(styles.saveButton)}
          disabled={onSaveIsDisabled()}
          onClick={onSave}
        >
          <SaveIcon style={{ marginRight: "1rem" }} />
          <Typography>Save and publish</Typography>
        </Button>
      </Badge>,
    ],
    pageContentClassName: styles.pageContent,
  };

  useEffect(() => {
    if (formIsDirty) {
      setUpdatingStatusPanelOpened(false);
    }
  }, [formIsDirty]);

  useEffect(() => {
    if (features) {
      const extendedCustomizationFeature = features.find(
        (feature) => feature.key === Features.extendedCustomizationShown,
      );
      setIsExtendedCustomizationShown(extendedCustomizationFeature?.value ?? false);
    }
  }, [features]);

  useEffect(() => {
    if (!client) return;
    const clientSettings = client.onboardingCustomizationSettings;
    resetField("companyName", {
      defaultValue: clientSettings?.friendlyName ?? "",
    });
    resetField("companyWebsite", {
      defaultValue: clientSettings?.websiteUrl ?? "",
    });
    resetField("panelBorderWidth", {
      defaultValue: clientSettings?.panelBorderWidth ?? Defaults.panelBorderWidth,
    });
    resetField("backgroundColour", {
      defaultValue: clientSettings?.pageBackgroundColour ?? Defaults.pageBackgroundColour,
    });
    resetField("panelBackgroundColour", {
      defaultValue: clientSettings?.panelBackgroundColour ?? Defaults.panelBackgroundColour,
    });
    resetField("borderColour", {
      defaultValue: clientSettings?.panelBorderColour ?? Defaults.panelBorderColour,
    });
    resetField("textColour", {
      defaultValue: clientSettings?.textColour ?? Defaults.textColour,
    });
    resetField("buttonColour", {
      defaultValue: clientSettings?.buttonColour ?? Defaults.buttonColour,
    });
    resetField("buttonTextColour", {
      defaultValue: clientSettings?.buttonTextColour ?? Defaults.buttonTextColour,
    });
    resetField("systems", {
      defaultValue: client.systems ?? [],
    });
    trigger();
  }, [client, resetField, trigger, isExtendedCustomizationShown]);

  useEffect(() => {
    if (updateSuccessfull) {
      setSelectedFile(undefined);
      reset({}, { keepValues: true });
    }
  }, [updateSuccessfull, reset]);

  useEffect(() => {
    setPreview(undefined);
    setSelectedFile(undefined);
  }, [client]);

  useEffect(() => {
    if (uploadingLogoProgress.error) {
      setUploadError(uploadingLogoProgress.error);
    }
  }, [uploadingLogoProgress.error]);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      return undefined;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setUploadError(null);
    setUpdatingStatusPanelOpened(false);
    if (e.target.files[0].size > logoUploadRequirements.logoSizeLimit) {
      setUploadError(logoUploadRequirements.logoSizeErrorMessage);
      setUpdatingStatusPanelOpened(true);
      return;
    }
    if (
      !logoUploadRequirements.acceptedFileFormats.includes(
        e.target.files[0].type,
      )
    ) {
      setUploadError(logoUploadRequirements.logoFormatErrorMessage);
      setUpdatingStatusPanelOpened(true);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const onSubmit = async (data) => {
    if (onSaveIsDisabled()) return;

    let logoDto: FormData | null = null;
    if (selectedFile) {
      logoDto = new FormData();
      logoDto.append("logo", selectedFile);
    }

    let clientSettings: {
      customizationSettings: UpdateOnboardingCustomizationSettingsDto
      systems: string[];
    } | null = null;
    if (formIsDirty) {
      const oldSettings = client?.onboardingCustomizationSettings;
      clientSettings = {
        customizationSettings: new UpdateOnboardingCustomizationSettingsDto(
          data.companyName,
          data.companyWebsite,
          data.panelBorderWidth
            ? data.panelBorderWidth
            : (oldSettings?.panelBorderWidth ?? Defaults.panelBorderWidth),
          data.backgroundColour
            ? data.backgroundColour
            : (oldSettings?.pageBackgroundColour ?? Defaults.pageBackgroundColour),
          data.panelBackgroundColour
            ? data.panelBackgroundColour
            : (oldSettings?.panelBackgroundColour ?? Defaults.panelBackgroundColour),
          data.borderColour
            ? data.borderColour
            : (oldSettings?.panelBorderColour ?? Defaults.panelBorderColour),
          data.textColour
            ? data.textColour
            : (oldSettings?.textColour ?? Defaults.textColour),
          data.buttonColour
            ? data.buttonColour
            : (oldSettings?.buttonColour ?? Defaults.buttonColour),
          data.buttonTextColour
            ? data.buttonTextColour
            : (oldSettings?.buttonTextColour ?? Defaults.buttonTextColour),
        ),
        systems: data.systems,
      };
    }

    setUpdatingStatusPanelOpened(true);
    await updateOnboardingSettings(logoDto, clientSettings);
  };

  const renderForm = () => (
    <div className={styles.optionsContainer}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
      >
        <YourCompanyAccordion
          badge={yourCompanyBadge}
          control={control}
          disableInputs={isUpdating || clientIsFetching}
          fieldErrors={formErrors}
          onChipClick={handleGoToStep(0)}
          onOpen={handleYourCompanyAccordionOpen}
          onClose={handleYourCompanyAccordionClose}
        />
        <ERPSystemsAccordion
          badge={erpSystemsBadge}
          control={control}
          disableInputs={isUpdating || clientIsFetching}
          onChipClick={handleGoToStep(1)}
          onOpen={handleGoToStep(1)}
        />
        <CustomizeUserOnboardingAccordion
          control={control}
          disableInputs={isUpdating || clientIsFetching}
          fieldErrors={formErrors}
          onSelectFile={onSelectFile}
          inputRef={fileInputRef}
          isExtendedCustomizationShown={isExtendedCustomizationShown}
        />
      </form>
    </div>
  );

  return (
    <StyledEngineProvider injectFirst>
      <CommonPageLayout {...pageLayoutParams}>
        <UploadingFileToast
          open={updatingStatusPanelOpened}
          close={onLogoUploadingStatusPanelClose}
          error={uploadError}
          progress={uploadingLogoProgress}
          inProgress={isUpdating}
          success={updateSuccessfull}
        />
        <Preview
          logoUrl={
            preview ?? client?.onboardingCustomizationSettings?.logoUrl ?? ""
          }
          companyName={companyNameWatch}
          theme={previewTheme}
          systems={systemsWatch}
          activeStep={activeStep}
          listRef={previewContainer}
          onScroll={handleScroll}
          goToStep={handleGoToStep}
        />
        {renderForm()}
      </CommonPageLayout>
    </StyledEngineProvider>
  );
};

export default BrandingPage;
