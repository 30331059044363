import { useQuery } from "@tanstack/react-query";
import _authService from "./services/AuthService";

export const getUserQueryKey = "user";
const useUserRepository = (authService = _authService) => {
  const {
    isLoading: userIsFetching,
    data: user,
    error: fetchUserError,
  } = useQuery({
    queryKey: [getUserQueryKey],
    queryFn: () => authService.getUserData(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    user,
    userIsFetching,
    fetchUserError,
    isAuthenticated: !!user?.isAuthenticated,

    login: authService.login,
    logout: authService.logout,
  };
};

export default useUserRepository;
