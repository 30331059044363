import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import { Typography, TextField, Button } from "@mui/material";
import useClient from "../../../application/useClient";

export type Inputs = {
  companyName: string;
  companyEmail: string;
  companyWebsite: string;
  companyId: string;
};
export interface CompanySettingsProps {
  onSubmit: SubmitHandler<Inputs>;
  defaultValues: Inputs;
}

const CompanySettings = ({ onSubmit, defaultValues }: CompanySettingsProps) => {
  const { clientIsFetching, clientIsUpdating } = useClient();
  const [companyNameIsValid, setCompanyNameIsValid] = useState(true);
  const [companyEmailIsValid, setCompanyEmailIsValid] = useState(true);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { isValid },
  } = useForm<Inputs>({
    mode: "onBlur",
    defaultValues,
  });

  const validateCompanyName = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target?.value?.match(/^(?!\s*$).+/)) {
      setCompanyNameIsValid(true);
    } else {
      setCompanyNameIsValid(false);
    }
  };

  const validateCompanyEmail = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target?.value?.match(/^\S+@\S+\.\S+$/)) {
      setCompanyEmailIsValid(true);
    } else {
      setCompanyEmailIsValid(false);
    }
  };

  useEffect(() => {
    trigger();
  }, [trigger]);

  if (clientIsFetching || clientIsUpdating) {
    return <Spinner />;
  }

  return (
    <div style={{ width: "35%" }}>
      <Typography variant="h6" component="h3" sx={{ marginBottom: "1rem" }}>
        Your Company Details
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="companyName">
          <Typography variant="subtitle2" gutterBottom>
            Your Company Name
          </Typography>
        </label>
        <Typography variant="body2" gutterBottom>
          The official name used for Client ↔︎ Zwapgrid identification
        </Typography>
        <TextField
          fullWidth
          required
          variant="outlined"
          title="Company Name"
          sx={{ marginBottom: "1rem" }}
          error={!companyNameIsValid}
          helperText={!companyNameIsValid && "Company name is required"}
          {...register("companyName", { required: true })}
          onBlur={validateCompanyName}
        />
        <label htmlFor="companyEmail">
          <Typography variant="subtitle2" gutterBottom>
            Your Company Email
          </Typography>
        </label>
        <Typography variant="body2" gutterBottom>
          Official email used for Client ↔︎ Zwapgrid communication
        </Typography>
        <TextField
          type="email"
          fullWidth
          variant="outlined"
          title="Company Email"
          sx={{ marginBottom: "1rem" }}
          error={!companyEmailIsValid}
          helperText={!companyEmailIsValid && "Valid email is required"}
          {...register("companyEmail", { required: true })}
          onBlur={validateCompanyEmail}
        />
        <label htmlFor="companyWebsite">
          <Typography variant="subtitle2" gutterBottom>
            Your Company Website
          </Typography>
        </label>
        <Typography variant="body2" gutterBottom>
          Optional
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          title="Company Website"
          sx={{ marginBottom: "1rem" }}
          {...register("companyWebsite")}
        />
        <label htmlFor="companyId">
          <Typography variant="subtitle2" gutterBottom>
            Your Company Id
          </Typography>
        </label>
        <Typography variant="body2" gutterBottom>
          Optional
        </Typography>
        <TextField
          fullWidth
          sx={{ marginBottom: "2rem" }}
          variant="outlined"
          title="Company Id"
          {...register("companyId")}
        />
        <br />
        <Button type="submit" disabled={!isValid || clientIsUpdating} fullWidth>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default CompanySettings;
