import React, { useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Button, Tooltip, Typography } from "@mui/material";
import { PopperButton, Spinner, TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

import CopyButtonInput from "../CopyButtonInput";
import styles from "./AddApiKeyModal.module.scss";
import useApiKey from "../../../application/useApiKey/useApiKey";
import ApiKeyExpirationTypeDto from "../../../infrastructure/dtos/ApiKeyExpirationTypeDto";

const AddApiKeyModal = ({ isTestMode }) => {
  const {
    createClientApiKey,
    createClientApiKeyResult,
    resetCreateClientApiKey,
    isCreatingClientApiKey,
    isCreateClientApiKeySuccess,
  } = useApiKey();

  const [apiKeyIsValid, setApiKeyIsValid] = useState(false);
  const popperRef = useRef({ close: () => undefined });
  const { register, control, handleSubmit, reset, formState } = useForm();
  const apiKeyExpiresInValue = useWatch({
    control,
    name: "apiKeyExpiresIn",
    defaultValue: "",
  });
  const resetModal = () => {
    resetCreateClientApiKey();
    reset();
    setApiKeyIsValid(false);
  };

  const onSubmit = async (formData) => {
    await createClientApiKey(formData.apiKeyName, formData.apiKeyDescription, formData.apiKeyExpiresIn, isTestMode);
  };

  const validateApiKeyName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.value?.match(/^(?!\s*$).+/)) {
      setApiKeyIsValid(true);
    } else {
      setApiKeyIsValid(false);
    }
  };
  const renderForm = () => {
    if (isCreateClientApiKeySuccess) return null;

    return (
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="apiKeyName" className={styles.label}>
          <Typography variant="body2" gutterBottom>
            Name API Key
          </Typography>
        </label>
        <TextField
          id="apiKeyName"
          color={apiKeyIsValid ? "success" : "info"}
          {...register("apiKeyName", { required: true })}
          onChange={validateApiKeyName}
          placeholder="What is the purpose of this key?"
          sx={{ mb: "1rem" }}
        />
        <label htmlFor="apiKeyExpiresIn" className={styles.label}>
          <Typography variant="body2" gutterBottom>
            When should this API key expire?
          </Typography>
        </label>
        <select
          id="apiKeyExpiresIn"
          {...register("apiKeyExpiresIn", {
            required: true,
            pattern: {
              value: /^(?!\s*$).+/,
              message: "",
            },
          })}
          className={classNames(styles.select, {
            [styles.validInput]: !formState.errors.apiKeyExpiresIn && apiKeyExpiresInValue !== "",
          })}
        >
          <option value="">Select Time Frame</option>
          <option value={ApiKeyExpirationTypeDto.In30Days}>In 30 days</option>
          <option value={ApiKeyExpirationTypeDto.In90Days}>In 90 days</option>
          <option value={ApiKeyExpirationTypeDto.In365Days}>In 365 days</option>
          <option value={ApiKeyExpirationTypeDto.Never}>Never</option>
        </select>
        <label htmlFor="apiKeyDescription" className={styles.label}>
          <Typography variant="body2" gutterBottom>
            Notes
          </Typography>
        </label>
        <TextField id="apiKeyDescription" {...register("apiKeyDescription")} type="text" sx={{ mb: "1rem" }} />
        <Tooltip title="Create New API Key" arrow>
          <span>
            <Button
              sx={{ width: "100%" }}
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid || formState.isSubmitting}
            >
              Save and Continue
            </Button>
          </span>
        </Tooltip>
      </form>
    );
  };

  const renderApiKey = () => {
    if (!isCreateClientApiKeySuccess || !createClientApiKeyResult?.apiKey) return null;

    return (
      <div className={styles.renderKeyContainer}>
        <Typography variant="caption" gutterBottom>
          Your new API Key is:
        </Typography>
        <CopyButtonInput value={createClientApiKeyResult.apiKey} />
        <Typography variant="caption" gutterBottom>
          Keys can not be retrieved once created. Be sure to save this Key somewhere safe, such as, a password management
          tool.
        </Typography>
        <Button variant="contained" onClick={popperRef.current.close}>
          Done
        </Button>
      </div>
    );
  };

  const renderSpinner = () => isCreatingClientApiKey && <Spinner inverted absolute />;

  return (
    <PopperButton
      buttonLabel={
        <>
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: "1rem" }} />
          Create New API Key
        </>
      }
      ref={popperRef}
      onClose={resetModal}
    >
      <div className={styles.modalContainer}>
        {renderForm()}
        {renderApiKey()}
        {renderSpinner()}
      </div>
    </PopperButton>
  );
};

export default AddApiKeyModal;
