import FileOneSettingsDto from "../dtos/FileOneSettings/FileOneSettingsDto";
import httpClient from "../httpClient";
import api from "../../constants/api/clientsApi";

async function get(
  clientId: string | undefined,
  signal = new AbortController().signal,
  errorHandler = () => undefined,
): Promise<FileOneSettingsDto> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get<FileOneSettingsDto>(api.fileOneSettings(clientId), requestOptions);

  return response.data;
}

async function update(
  clientId: string | undefined,
  fileOneSettings: FileOneSettingsDto,
  signal = new AbortController().signal,
  errorHandler = () => undefined,
): Promise<void> {
  const requestOptions = {
    errorHandler,
    signal,
    headers: { "If-Match": fileOneSettings.etag },
  };
  await httpClient.instance.put(api.fileOneSettings(clientId), fileOneSettings, requestOptions);
}

export default {
  get,
  update,
};
