import { Spinner } from "@zwapgrid/zwapgrid-ui-component-library";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import dateFormat from "dateformat";
import { Collapse, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { MouseEvent, useContext, useState } from "react";
import CommonPageLayout from "../../components/CommonPageLayout";
import _useApiKeyList from "../../../application/useApiKey/useApiKeyList";
import styles from "./ApiKeyPage.module.scss";
import ApiKey from "../../../domain/models/ApiKey";
import AddApiKeyModal from "../../components/AddApiKeyModal";
import DeleteApiKeyModal from "../../components/DeleteApiKeyModal/DeleteApiKeyModal.component";
import _useClient from "../../../application/useClient";
import ProdModeSwitch from "../../components/ProdModeSwitch/ProdModeSwitch";
import IsProdModeContext from "../../IsProdModeContext";

const ApiKeyPage = ({ useApiKeyList = _useApiKeyList, useClient = _useClient }) => {
  const [currentApiKey, setCurrentApiKey] = useState<ApiKey>();
  const { apiKeyList, apiKeyListIsFetching } = useApiKeyList();
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const { client, clientIsFetching } = useClient();
  const { isProdMode, setIsProdMode } = useContext(IsProdModeContext);

  const filteredApiKeyList = apiKeyList?.data.filter((x) => x.isTest === !isProdMode);

  const pageLayoutParams = {
    headerText: "API Key",
    subheaderText: "",
    headerElements: [
      <AddApiKeyModal key="1" isTestMode={!isProdMode} />,
      <ProdModeSwitch
        key="2"
        isProdMode={isProdMode}
        setIsProdMode={setIsProdMode}
        isTrial={Boolean(client?.isTrial)}
        isLoading={clientIsFetching}
        disabled={!client}
      />,
    ],
  };

  const isExpanded = (entityId: string) => expandedRows.includes(entityId);

  const handleOpenDeleteModal = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, apiKey: ApiKey) => {
    e.stopPropagation();
    setCurrentApiKey(apiKey);
    setOpenDeleteModal(true);
  };
  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteApiKeyModalParams = {
    apiKeyToDelete: currentApiKey,
    open: openDeleteModal,
    close: handleCloseDeleteModal,
  };

  const renderDeleteApiKeyModal = () => currentApiKey && <DeleteApiKeyModal {...deleteApiKeyModalParams} />;

  const toggleExpandedRow = (entityId: string) => {
    if (expandedRows.includes(entityId)) {
      setExpandedRows((old) => [...old.filter((e) => e !== entityId)]);
    } else {
      setExpandedRows((old) => [...old, entityId]);
    }
  };
  const renderSpinner = () => apiKeyListIsFetching && <Spinner inverted absolute />;

  const renderNoDataMessage = () => {
    if (apiKeyListIsFetching || !filteredApiKeyList) return null;

    if (filteredApiKeyList.length > 0) return null;

    return <Typography className={styles.emptyList}>You do not have any API keys yet</Typography>;
  };

  const renderStatusIcon = (isActive: boolean) => {
    if (isActive) {
      return <FontAwesomeIcon className={classNames(styles.statusIcon)} icon={faXmark} />;
    }

    return <FontAwesomeIcon className={classNames(styles.statusIcon, styles.notExpired)} icon={faCheck} />;
  };
  const renderDataRow = (entity: ApiKey, index: number) => {
    const isExpired = entity.expiresOn ? new Date(entity.expiresOn) < new Date() : false;
    const handleExpand = () => toggleExpandedRow(entity.id);
    const formattedExpiredDate = entity.expiresOn ? dateFormat(entity.expiresOn, "mmmm d, yyyy. H:MM") : "";

    let expiresOn = "Does not Expire";
    if (entity.expiresOn) {
      expiresOn = isExpired ? `Expired ${formattedExpiredDate}` : `Expires ${formattedExpiredDate}`;
    }

    return (
      <div key={index} className={classNames(styles.tableRow)}>
        <div
          className={classNames(styles.tableRow, {
            [styles.expired]: isExpired,
          })}
        >
          <Typography className={classNames(styles.tableCell)}>{renderStatusIcon(isExpired)}</Typography>
          <Typography className={styles.tableCell}>
            <span>{entity.name}</span>
          </Typography>
          <Typography className={styles.tableCell}>
            <span className={styles.textField}>{entity.value}</span>
          </Typography>
          <Typography className={classNames(styles.tableCell)}>
            {dateFormat(entity.createdOn, "mmmm d, yyyy. H:MM")}
          </Typography>
          <Typography
            className={classNames(styles.tableCell, {
              [styles.notExpired]: !isExpired,
              [styles.expired]: isExpired,
            })}
          >
            {expiresOn}
          </Typography>
          <Typography className={classNames(styles.tableCell)}>
            <Tooltip title="Delete API Key" arrow placement="top">
              <IconButton
                className={classNames(styles.deleteButton)}
                aria-label="delete"
                onClick={(e) => handleOpenDeleteModal(e, entity)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            {entity.description && (
              <Tooltip title={isExpanded(entity.id) ? "Collapse row" : "Expand row"} arrow placement="top">
                <IconButton aria-label={isExpanded(entity.id) ? "collapse" : "expand"} onClick={handleExpand}>
                  <ExpandMoreIcon
                    className={
                      isExpanded(entity.id)
                        ? classNames(styles.expandIcon, styles.rotateExpandIcon)
                        : classNames(styles.expandIcon)
                    }
                  />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
        </div>
        <Collapse
          data-testid="expanded-element"
          in={isExpanded(entity.id)}
          classes={{
            root: styles.collapse,
            wrapperInner: styles.collapseWrapperInner,
          }}
        >
          <Typography className={styles.collapseCell}>
            {entity.description && <span className={styles.textField}>{entity.description}</span>}
          </Typography>
        </Collapse>
      </div>
    );
  };

  const renderDataTable = () => {
    if (apiKeyListIsFetching || !filteredApiKeyList) return null;

    if (filteredApiKeyList.length < 1) return null;

    return (
      <div className={styles.tableContainer}>
        <div className={styles.tableHeader}>
          <div className={styles.tableHeaderCell} />
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Name
          </Typography>
          <div className={styles.tableHeaderCell} />
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Created On
          </Typography>
          <div className={styles.tableHeaderCell} />
          <Typography variant="subtitle1" className={styles.tableHeaderCell}>
            Actions
          </Typography>
        </div>
        {filteredApiKeyList.map((x, index) => renderDataRow(x, index))}
        {renderDeleteApiKeyModal()}
      </div>
    );
  };

  return (
    <CommonPageLayout {...pageLayoutParams}>
      {renderSpinner()}
      {renderNoDataMessage()}
      {renderDataTable()}
    </CommonPageLayout>
  );
};

export default ApiKeyPage;
