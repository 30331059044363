import { useNavigate, useSearchParams } from 'react-router-dom';
import sharedConsts from '../../constants/SharedConsts';

const useRedirect = () => {
  const [searchParams] = useSearchParams();
  let redirectUrl;
  searchParams.forEach((value, key) => {
    if (key.toLowerCase() === sharedConsts.redirectUrlKey) {
      redirectUrl = value;
    }
  });
  const navigate = useNavigate();

  const redirect = () => {
    if (redirectUrl) {
      let finalRedirectUrl = decodeURI(redirectUrl);
      if (!finalRedirectUrl.startsWith('http')) {
        finalRedirectUrl = `http://${finalRedirectUrl}`;
      }
      window.location.replace(finalRedirectUrl);
    } else {
      navigate('/');
    }
  };

  return { redirect, redirectExists: !!redirectUrl, redirectUrl };
};

export default useRedirect;
