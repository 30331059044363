import { CircleProgressBar } from "@zwapgrid/zwapgrid-ui-component-library";
import {
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import styles from "./UploadingFileToast.module.scss";
import UploadingFileProgress from "../../../domain/models/UploadingFileProgress";

type UploadingFileToastProps = {
  open: boolean;
  close: () => void;
  error: string | null;
  progress: UploadingFileProgress;
  inProgress: boolean;
  success: boolean;
  disableClose?: boolean;
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}
const UploadingFileToast = ({
  open,
  close,
  error,
  progress,
  inProgress,
  success,
  disableClose = false,
}: UploadingFileToastProps) => (
  <Snackbar
    open={open}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    TransitionComponent={SlideTransition}
  >
    <span className={styles.StatusPanel}>
      {inProgress && (
        <>
          <CircleProgressBar progress={progress.progress} />
          <Typography>Saving changes...</Typography>
        </>
      )}
      {success && !error && (
        <>
          <FontAwesomeIcon icon={faCheck} color="green" />
          <Typography>Changes are saved</Typography>
        </>
      )}
      {error && (
        <>
          <FontAwesomeIcon icon={faExclamation} color="red" />
          <Typography>{error}</Typography>
        </>
      )}
      <IconButton aria-label="close" disabled={disableClose} onClick={close}>
        <FontAwesomeIcon icon={faXmark} />
      </IconButton>
    </span>
  </Snackbar>
);

export default UploadingFileToast;
