import { useSnackbar } from "notistack";
import useAuth from "./auth/useAuth";
import ConsentRepository from "../infrastructure/consent.repository";
import ConsentListRepository from "../infrastructure/consentList.repository";
import ConsentStatus from "../domain/models/ConsentStatus";

export default (consentId: string | undefined) => {
  const { user } = useAuth();
  if (!user) throw new Error("User not found");

  const consentRepository = ConsentRepository(consentId, user.clientId);
  const consentListRepository = ConsentListRepository();
  const { enqueueSnackbar } = useSnackbar();

  const revokeConsentAndShowToastMessage = async (ifMatch: string | undefined) => {
    await consentRepository.patch(ConsentStatus.Revoked, null, ifMatch);
    await consentListRepository.refreshConsentList();
    enqueueSnackbar("Consent revoked successfully", { variant: "success" });
  };

  const updateConsentName = async (consentName: string, ifMatch: string | undefined) => {
    await consentRepository.patch(null, consentName, ifMatch);
    await consentListRepository.refreshConsentList();
    enqueueSnackbar("Consent name updated successfully", { variant: "success" });
  };

  const deleteConsent = async () => {
    try {
      await consentRepository.deleteConsent(true);
      enqueueSnackbar("Consent deleted successfully", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Error deleting consent", { variant: "error" });
    }
  };

  return {
    ...consentRepository,
    revokeConsentAndShowToastMessage,
    updateConsentName,
    deleteConsent,
  };
};
