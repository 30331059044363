import React from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import { PopperButton } from "@zwapgrid/zwapgrid-ui-component-library";
import { ReactComponent as MyConsentsIcon } from "../../assets/icons/Consents.svg";
import { ReactComponent as GearIcon } from "../../assets/icons/Gear.svg";
import { ReactComponent as BrandingIcon } from "../../assets/icons/Branding.svg";
import { ReactComponent as KeyIcon } from "../../assets/icons/Key.svg";
import useAuth from "../../../application/auth/useAuth";
import styles from "./MyAccountModal.module.scss";
import config from "../../../config";

type Options = {
  label: string;
  icon: React.ReactElement;
  onClick: () => void;
};

export const MyAccountModal = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const handleLogoutBtnClick = () => logout(config.AUTH.LOGOUT_URL);

  const optionsList: Options[] = [
    {
      label: "My Consents",
      icon: <MyConsentsIcon />,
      onClick: () => navigate("/consents"),
    },
    {
      label: "Branding",
      icon: <BrandingIcon />,
      onClick: () => navigate("/branding"),
    },
    {
      label: "Company Settings",
      icon: <img src="/assets/icons/CompanySettings.png" className={styles.icon} alt="Company settings icon" />,
      onClick: () => navigate("/settings"),
    },
    {
      label: "API Key",
      icon: <KeyIcon />,
      onClick: () => navigate("/apikeys"),
    },
    {
      label: "System Settings",
      icon: <GearIcon />,
      onClick: () => navigate("/systemsettings"),
    },
  ];

  const renderOption = (option: Options, key) => (
    <button key={key} type="button" className={styles.optionButton} onClick={option.onClick}>
      {option.icon}
      <Typography>{option.label}</Typography>
    </button>
  );

  const renderOptions = () => {
    if (optionsList.length === 0) return null;
    return <section className={styles.optionsContainer}>{optionsList.map((option, i) => renderOption(option, i))}</section>;
  };

  return (
    <PopperButton variant="elevated" buttonLabel={<Typography>My Account</Typography>} placement="bottom-end">
      <div className={styles.content}>
        {renderOptions()}
        <Button variant="outlined" onClick={handleLogoutBtnClick}>
          Logout
        </Button>
      </div>
    </PopperButton>
  );
};

export default MyAccountModal;
