import api from "../../constants/api/featureApi";
import httpClient from "../httpClient";
import Feature from "../../domain/models/Feature";

async function getFeatures(
  signal: AbortSignal | undefined = undefined,
  errorHandler = () => undefined,
): Promise<Feature[]> {
  const requestOptions = {
    errorHandler,
    signal,
  };
  const response = await httpClient.instance.get(api.feature(), requestOptions);
  return response.data;
}

export default {
  getFeatures,
};
