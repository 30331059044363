import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Chip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Control, Controller } from "react-hook-form";
import { SyntheticEvent } from "react";
import supportedSystems from "../../../../../constants/SupportedSystems";
import SystemButton from "../../../../components/SystemButton/SystemButton.component";
import { BadgeType, FormValues } from "../../BrandingPage";
import { ReactComponent as ERPSystemsIcon } from "../../../../assets/icons/ERPSystems.svg";
import styles from "../Accordion.module.scss";

type ERPSystemsAccordionProps = {
  badge: BadgeType;
  control: Control<FormValues>;
  disableInputs?: boolean;
  onChipClick: () => void;
  onOpen?: () => void;
  onClose?: () => void;
};

const ERPSystemsAccordion = ({
  badge,
  control,
  disableInputs = false,
  onChipClick,
  onOpen,
  onClose,
}: ERPSystemsAccordionProps) => {
  const handleChange = (event: SyntheticEvent, expanded: boolean) => {
    if (expanded) {
      onOpen?.();
    } else {
      onClose?.();
    }
  };

  const toggleSystem = (systems: string[], key: string) => {
    if (systems.includes(key)) {
      return systems.filter((system) => system !== key);
    }
    return [...systems, key];
  };

  return (
    <Badge badgeContent={badge.content} color={badge.color}>
      <Accordion
        disableGutters
        className={styles.accordion}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          className={styles.accordionSummary}
        >
          <Typography
            variant="h5"
            component="h2"
            className={styles.accordionSummaryContent}
          >
            <ERPSystemsIcon className={styles.accordionSummaryContentIcon} />
            ERP Systems
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classNames(styles.accordionDetails)}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            className={styles.accordionDetailDescription}
          >
            Toggle ERP Systems
            <Chip label="Show Preview" onClick={onChipClick} />
          </Typography>
          <Typography variant="body2" gutterBottom>
            Select which ERP systems you would like to offer your customers in
            the onboarding journey.
          </Typography>
          <div className={styles.systemSelection}>
            {Object.values(supportedSystems).map((system) => (
              <Controller
                key={system.key}
                name="systems"
                control={control}
                render={({ field }) => (
                  <SystemButton
                    {...field}
                    value={system.key}
                    system={system}
                    checked={field.value.includes(system.key)}
                    disabled={disableInputs}
                    onChange={() =>
                      field.onChange(toggleSystem(field.value, system.key))
                    }
                  />
                )}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </Badge>
  );
};
export default ERPSystemsAccordion;
