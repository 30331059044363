import React from "react";
import { Typography } from "@mui/material";
import classNames from "classnames";
import styles from "./CommonPageLayout.module.scss";
import MyAccountModal from "../MyAccountModal";

type Props = {
  children: React.ReactNode;
  headerText?: string;
  subheaderText?: string;
  headerElements?: React.ReactNode[];
  CTAButton?: React.ReactNode;
  pageContentClassName?: string;
  showMyAccountModal?: boolean;
};

const CommonPageLayout = ({
  children,
  headerElements,
  headerText,
  subheaderText,
  CTAButton,
  pageContentClassName,
  showMyAccountModal = true,
}: Props) => (
  <div className={styles.page}>
    <header className={styles.headerContainer}>
      {headerText && (
        <Typography
          variant="h6"
          component="h1"
          sx={{ textWrap: "nowrap", mr: "4rem", fontWeight: "bold" }}
        >
          {headerText}
        </Typography>
      )}
      {CTAButton}
      {headerElements}
      {showMyAccountModal && (
        <aside>
          <MyAccountModal />
        </aside>
      )}
    </header>
    <Typography variant="subtitle1" className={styles.subheader}>
      {subheaderText}
    </Typography>
    <main className={classNames(styles.pageContent, pageContentClassName)}>
      {children}
    </main>
  </div>
);

export default CommonPageLayout;
