import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { OnboardingLayout } from "@zwapgrid/zwapgrid-ui-component-library";
import styles from "./SelectSystemPage.module.scss";
import SupportedSystems from "../../../constants/SupportedSystems";
import SystemButton from "../SystemButton/SystemButton.component";

type SelectSystemPageProps = {
  logoUrl: string;
  friendlyName: string;
  onSubmit: (event) => void;
  systems: string[];
};

const SelectSystemPage = ({
  logoUrl,
  friendlyName,
  onSubmit,
  systems,
}: SelectSystemPageProps) => {
  const [selectedSystem, setSelectedSystem] = useState<string>("");

  const handleSystemSelect = (key: string) => () => {
    setSelectedSystem(key);
  };

  return (
    <OnboardingLayout
      logoUrl={logoUrl}
      logoUrlAltText={friendlyName}
      containerClassName={styles.onboardingLayout}
    >
      <form className={styles.form} onSubmit={onSubmit}>
        <Typography variant="h6" component="h2" align="center">
          Please choose your invoicing system:
        </Typography>
        <div className={styles.sourceSelector}>
          {Object.entries(SupportedSystems)
            .filter(([, value]) => systems.includes(value.key))
            .map(([key, value]) => (
              <SystemButton
                key={key}
                value={key}
                system={value}
                checked={selectedSystem === key}
                onChange={handleSystemSelect(key)}
              />
            ))}
        </div>
        <Button
          type="submit"
          variant="contained"
          size="large"
          className={styles.btnPrimary}
          disabled={!selectedSystem}
          fullWidth
        >
          Next
        </Button>
      </form>
    </OnboardingLayout>
  );
};
export default SelectSystemPage;
