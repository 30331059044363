import { useEffect, useRef } from "react";
import { IconButton, Tooltip } from "@mui/material";
import clipboardService from "../../../application/services/clipboardService";

import { ReactComponent as CopyIcon } from "../../assets/icons/Copy.svg";
import styles from "./CopyButtonInput.module.scss";

type Props = {
  value?: string | undefined;
  title?: string | undefined;
};

const CopyButtonInput = ({ value, title }: Props) => {
  const copyValueToClipboard = async () => {
    if (value) {
      await clipboardService.copyValue(value);
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.scrollLeft = inputRef.current.scrollWidth;
    }
  }, []);

  return (
    <div className={styles.copyButtonInputContainer}>
      <input type="text" value={value} ref={inputRef} disabled />
      <Tooltip title={title ?? "Copy"} arrow placement="left">
        <IconButton
          onClick={copyValueToClipboard}
          sx={{ float: "right", borderRadius: "4px" }}
        >
          <CopyIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CopyButtonInput;
