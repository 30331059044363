enum ApiKeyExpirationTypeDto
{
  In30Days = 0,

  In90Days = 1,

  In365Days = 2,

  Never = 3,
}

export default ApiKeyExpirationTypeDto;
