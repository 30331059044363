import { useQuery } from "@tanstack/react-query";
import _featureService from "./services/featureService";

export const featureQueryKey = "feature";
const useFeatureListRepository = (featureService = _featureService) => {
  const {
    isLoading: featuresAreFetching,
    data: features,
  } = useQuery({
    queryKey: [featureQueryKey],
    queryFn: () => featureService.getFeatures(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  return {
    features,
    featuresAreFetching,
  };
};

export default useFeatureListRepository;
