import { enqueueSnackbar } from "notistack";
import useAuth from "./auth/useAuth";
import useFileOneSettingsRepository from "../infrastructure/fileoneSettings.repository";
import FileOneSettingsDto from "../infrastructure/dtos/FileOneSettings/FileOneSettingsDto";

export default () => {
  const { user } = useAuth();
  if (!user) throw new Error("User not found");

  const fileOneRepository = useFileOneSettingsRepository(user.clientId);

  const updateFileOneSettings = async (fileOneSettings: FileOneSettingsDto) => {
    await fileOneRepository.update(fileOneSettings);
    enqueueSnackbar("FileOne settings updated successfully", { variant: "success" });
  };

  return {
    ...fileOneRepository,
    updateFileOneSettings,
  };
};
