import { useIsMutating, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import _consentService from "./services/consentService";
import HttpRequestError from "../domain/exceptions/HttpRequestError";
import UpdateConsentMutationDto from "../application/dtos/UpdateConsentMutationDto";
import ConsentStatus from "../domain/models/ConsentStatus";
import UpdateConsentDto from "../application/dtos/UpdateConsentDto";
import useConsentListRepository from "./consentList.repository";

const getConsentQueryKey = "getConsent";
const patchConsentMutationKey = "patchConsent";
const deleteConsentMutationKey = "deleteConsent";

const useConsentRepository = (consentId: string | undefined, clientId: string | undefined) => {
  const { refreshConsentList } = useConsentListRepository();

  const {
    isLoading,
    data: consent,
    error,
    isFetching,
  } = useQuery({
    queryKey: [getConsentQueryKey, consentId],
    queryFn: async () => _consentService.getClientConsent(clientId, consentId),
    enabled: !!consentId && !!clientId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
  });

  const queryClient = useQueryClient();
  const refresh = (id: string) => {
    queryClient.invalidateQueries([getConsentQueryKey, id], { exact: true });
  };

  const { mutateAsync: patchAsync } = useMutation({
    mutationKey: [patchConsentMutationKey],
    mutationFn: async (parameters: UpdateConsentMutationDto) =>
      _consentService.patchClientConsent(
        clientId,
        consentId,
        new UpdateConsentDto(parameters.status, parameters.name, parameters.ifMatch),
      ),
  });

  async function patch(status: ConsentStatus | null, name: string | null, ifMatch: string | undefined) {
    if (!consentId || !clientId) throw new Error("consentId or clientId is not set");
    return patchAsync(new UpdateConsentMutationDto(status, name, ifMatch));
  }

  const { mutateAsync: deleteAsync } = useMutation({
    mutationKey: [deleteConsentMutationKey],
    mutationFn: async ({ id, hideErrorMessage }: { id: string; hideErrorMessage: boolean }) => {
      if (!clientId) throw new Error("clientId is not set");
      return _consentService.deleteClientConsent(clientId, id, hideErrorMessage);
    },
    onSuccess: async () => {
      await refreshConsentList();
    },
  });

  const deleteConsent = async (hideErrorMessage = false) => {
    if (!consentId) throw new Error("consentId is not set");
    return deleteAsync({ id: consentId, hideErrorMessage });
  };

  return {
    patch,
    refresh,
    consent,
    consentIsBeingFetched: isLoading || isFetching,
    fetchConsentError: error as HttpRequestError | null,
    consentIsBeingUpdated: !!useIsMutating([patchConsentMutationKey]),
    deleteConsent,
  };
};

export default useConsentRepository;
