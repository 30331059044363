import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Chip,
  Typography,
} from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Control, Controller, FieldErrors } from "react-hook-form";
import { TextField } from "@zwapgrid/zwapgrid-ui-component-library";
import { SyntheticEvent } from "react";
import { ReactComponent as GearIcon } from "../../../../assets/icons/Gear.svg";

import styles from "../Accordion.module.scss";
import { BadgeType, FormValues } from "../../BrandingPage";

type YourCompanyAccordionProps = {
  badge: BadgeType;
  control: Control<FormValues>;
  disableInputs?: boolean;
  fieldErrors?: FieldErrors<FormValues>;
  onChipClick: () => void;
  onOpen?: () => void;
  onClose?: () => void;
};
export const YourCompanyAccordion = ({
  badge,
  control,
  disableInputs = false,
  fieldErrors = {},
  onChipClick,
  onOpen,
  onClose,
}: YourCompanyAccordionProps) => {
  const handleChange = (event: SyntheticEvent, expanded: boolean) => {
    if (expanded) {
      onOpen?.();
    } else {
      onClose?.();
    }
  };

  return (
    <Badge badgeContent={badge.content} color={badge.color}>
      <Accordion
        disableGutters
        className={styles.accordion}
        onChange={handleChange}
      >
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faChevronDown} />}
          className={styles.accordionSummary}
        >
          <Typography
            variant="h5"
            component="h2"
            className={styles.accordionSummaryContent}
          >
            <GearIcon className={styles.accordionSummaryContentIcon} />
            Your Company
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionDetails}>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            className={styles.accordionDetailDescription}
          >
            Your Company Details
            <Chip label="Show Preview" onClick={onChipClick} />
          </Typography>
          <Typography variant="body2" gutterBottom>
            Select which name and link you want to show your customers in the
            onboarding journey.
          </Typography>
          <label htmlFor="name">
            <Typography variant="subtitle2">Your Company Name</Typography>
          </label>
          <Badge badgeContent={badge.inputContent} color={badge.color}>
            <Controller
              name="companyName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  title="Input your company name"
                  id="name"
                  color={field.value ? "success" : "primary"}
                  {...field}
                  disabled={disableInputs}
                  aria-disabled={disableInputs}
                  sx={{ mb: "1rem" }}
                  fullWidth
                  error={!!fieldErrors.companyName}
                  type="text"
                />
              )}
            />
          </Badge>
          <label htmlFor="companyWebsite">
            <Typography variant="subtitle2">Your Company Website</Typography>
          </label>
          <Controller
            name="companyWebsite"
            control={control}
            render={({ field }) => (
              <TextField
                color={field.value ? "success" : "primary"}
                {...field}
                sx={{ mb: "1rem" }}
                disabled={disableInputs}
                error={!!fieldErrors.companyWebsite}
              />
            )}
          />
        </AccordionDetails>
      </Accordion>
    </Badge>
  );
};

export default YourCompanyAccordion;
