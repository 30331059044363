import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import {
  LibraryThemeProvider,
  theme,
} from "@zwapgrid/zwapgrid-ui-component-library";
import "@zwapgrid/zwapgrid-ui-component-library/style.css";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import reportWebVitals from "./reportWebVitals";
import Root from "./presentation/pages/Root";
import SnackbarConfig from "./constants/SnackbarConfig";
import ProtectedRoute from "./application/auth/ProtectedRoute";
import "./index.css";

const ProtectedRoot = ProtectedRoute(Root);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <LibraryThemeProvider theme={theme}>
        <SnackbarProvider {...SnackbarConfig}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <ProtectedRoot />
            </Router>
          </QueryClientProvider>
        </SnackbarProvider>
      </LibraryThemeProvider>
    </ThemeProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
