import ConsentStatus from "../../domain/models/ConsentStatus";

export default class UpdateConsentMutationDto {
  status: ConsentStatus | null;

  name: string | null;

  ifMatch: string | undefined;

  constructor(status: ConsentStatus | null, name: string | null, ifMatch: string | undefined) {
    this.status = status;
    this.name = name;
    this.ifMatch = ifMatch;
  }
}
