import React from "react";

type IsProdModeContextType = {
  isProdMode: boolean;
  setIsProdMode: React.Dispatch<React.SetStateAction<boolean>>;
};

const IsProdModeContext = React.createContext<IsProdModeContextType>({
  isProdMode: false,
  setIsProdMode: () => {
    /* Default context value intentionally left blank */
  },
});

export default IsProdModeContext;
