export default class UploadingFileProgress {
  name: string;

  progress: number;

  error: string | null;

  finished: boolean;

  constructor(
    name: string,
    progress: number,
    error: string | null,
    finished: boolean,
  ) {
    this.name = name;
    this.progress = progress;
    this.error = error;
    this.finished = finished;
  }
}
