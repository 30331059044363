export default class UpdateSettingsDto {
  Name: string;

  Email: string;

  Website: string;

  Identifier: string;

  constructor(
    name: string,
    email: string,
    website: string,
    identifier: string,
  ) {
    this.Name = name;
    this.Email = email;
    this.Website = website;
    this.Identifier = identifier;
  }
}
