import ApiKeyExpirationTypeDto from "./ApiKeyExpirationTypeDto";

export default class CreateClientApiKeyDto {
  name: string;

  description: string | null;

  isTest: boolean;

  expiresIn: ApiKeyExpirationTypeDto;

  constructor(name: string, description: string | null, isTest: boolean, expiresIn: ApiKeyExpirationTypeDto) {
    this.name = name;
    this.description = description;
    this.isTest = isTest;
    this.expiresIn = expiresIn;
  }
}
