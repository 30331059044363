import {useState} from "react";
import {AxiosProgressEvent} from "axios";
import useClientRepository from "../infrastructure/client.repository";
import UploadingFileProgress from "../domain/models/UploadingFileProgress";
import HttpRequestError from "../domain/exceptions/HttpRequestError";
import UpdateOnboardingCustomizationSettingsDto from "../infrastructure/dtos/UpdateOnboardingCustomizationSettingsDto";

export const useClient = () => {
  const clientRepo = useClientRepository();
  const {client, clientIsFetching} = clientRepo;
  const [uploadingLogoProgress, setUploadingLogoProgress] =
    useState<UploadingFileProgress>(
      new UploadingFileProgress("", 0, null, false),
    );

  const updateOnboardingSettings = async (
    logoDto: FormData | null,
    clientSettings: {
      customizationSettings: UpdateOnboardingCustomizationSettingsDto;
      systems: string[];
    } | null,
  ) => {
    setUploadingLogoProgress(new UploadingFileProgress("logo", 0, null, false));
    if (!client || clientIsFetching) return;

    const onProgress = (progressEvent: AxiosProgressEvent) => {
      if (!progressEvent.total) {
        setUploadingLogoProgress((prev) => ({
          ...prev,
          progress: 0,
        }));

        return;
      }

      const progress = progressEvent.total === progressEvent.loaded
        ? 100
        : Math.round((100 * progressEvent.loaded) / progressEvent.total);

      setUploadingLogoProgress((prev) => ({
        ...prev,
        progress,
      }));
    };

    const onFinish = () => {
      setUploadingLogoProgress((prev) => ({
        ...prev,
        finished: true,
      }));
    };

    const onError = (error: string) => {
      setUploadingLogoProgress((prev) => ({
        ...prev,
        error,
      }));
    };

    try {
      await clientRepo.updateOnboardingSettings({
        logoDto,
        clientSettings,
        progressCallBack: (progressEvent: AxiosProgressEvent) =>
          onProgress(progressEvent),
      });

      onFinish();
    } catch (e) {
      const exception = e as HttpRequestError;
      onError(exception.message);
      onFinish();
    }
  };

  return {...clientRepo, updateOnboardingSettings, uploadingLogoProgress};
};

export default useClient;
